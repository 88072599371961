import React, { useEffect, useLayoutEffect, useRef } from "react";
import classNames from "classnames";
import { FeatherIcon } from "../assets/icons";
import { usePrevious, useToggle } from "react-use";
import { Button, ButtonProps } from "react-bootstrap";
import { getThrottledCallback } from "../utils/other";
import { useUnmountedValue } from "../other/hooks";
import { BTN_LOADER_MIN_DELAY } from "../config";
import { useTranslation } from "react-i18next";

export interface ButtonWithLoaderProps extends ButtonProps {
  isLoading?: boolean;
}

export const ButtonWithLoader = React.memo<ButtonWithLoaderProps>(
  ({ isLoading, ...props }) => {
    const [t] = useTranslation();
    const children = props.children;
    const loaderRef = useRef<HTMLSpanElement | null>(null);
    const hideLoaderFn = useRef<Function>();
    const [showLoader, setShowLoader] = useToggle(!!isLoading);
    const isUnmounted = useUnmountedValue();
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useFreezeButtonWidth(buttonRef, isLoading || showLoader);
    // Show loader
    useEffect(() => {
      if (!isLoading) return;
      setShowLoader(true);

      let dots = "";
      const timer = setInterval(() => {
        if (!loaderRef.current) return;
        if (dots.length >= 3) dots = "";
        dots += ".";
        loaderRef.current.innerHTML = "Loading" + dots;
      }, 100);

      // Save delayed callback
      hideLoaderFn.current = getThrottledCallback(() => {
        if (timer) clearInterval(timer);
        if (!isUnmounted.current) setShowLoader(false);
      }, BTN_LOADER_MIN_DELAY);
    }, [isLoading, isUnmounted, setShowLoader]);

    // Remove the loader
    useEffect(() => {
      if (!isLoading) hideLoaderFn.current?.();
    }, [isLoading]);
    return (
      <Button
        {...props}
        disabled={isLoading || showLoader || props.disabled}
        className={classNames(props.className, "is-loading")}
        ref={buttonRef}
      >
        {isLoading || showLoader ? (
          <span ref={loaderRef}>{t("general.loading")}</span>
        ) : (
          children
        )}
      </Button>
    );
  }
);
export const ButtonWithIcon = React.memo<
  ButtonProps & { icon: JSX.Element; isLoading?: boolean }
>(({ icon, isLoading, children, ...props }) => {
  const hideLoaderFn = useRef<Function>();
  const [showLoader, setShowLoader] = useToggle(!!isLoading);
  const isUnmounted = useUnmountedValue();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useFreezeButtonWidth(buttonRef, isLoading || showLoader);
  // Show loader
  useEffect(() => {
    if (!isLoading) return;
    setShowLoader(true);

    // Save delayed callback
    hideLoaderFn.current = getThrottledCallback(() => {
      if (!isUnmounted.current) setShowLoader(false);
    }, BTN_LOADER_MIN_DELAY);
  }, [isLoading, isUnmounted, setShowLoader]);

  // Remove the loader
  useEffect(() => {
    if (!isLoading) hideLoaderFn.current?.();
  }, [isLoading]);

  return (
    <Button
      {...props}
      className={classNames("btn-with-icon", props.className)}
      ref={buttonRef}
    >
      <span>{children}</span>
      {isLoading || showLoader ? (
        <FeatherIcon.Loader className="spinner-loader" />
      ) : (
        icon
      )}
    </Button>
  );
});

export const NextButton: React.FC<
  ButtonWithLoaderProps & {
    text?: string;
  }
> = ({ text, ...props }) => {
  const [t] = useTranslation();

  return (
    <ButtonWithIcon
      variant="primary"
      icon={<FeatherIcon.ChevronRight />}
      {...props}
    >
      {text ?? t("general.next")}
    </ButtonWithIcon>
  );
};

function useFreezeButtonWidth(
  buttonRef: React.RefObject<HTMLButtonElement>,
  isLoading: boolean
) {
  const widthBefore = useRef<string>();
  const isLoadingPrevious = usePrevious(isLoading);

  useEffect(() => {
    if (!isLoading && buttonRef.current) {
      widthBefore.current = buttonRef.current!.offsetWidth + "px";
    }
  }, [buttonRef, isLoading]);

  // Freeze/Unfreeze width
  useLayoutEffect(() => {
    if (buttonRef.current) {
      if (!isLoadingPrevious && isLoading) {
        buttonRef.current.style.minWidth = widthBefore.current!;
      }
      if (isLoadingPrevious && !isLoading) {
        buttonRef.current.style.removeProperty("min-width");
      }
    }
  }, [buttonRef, isLoading, isLoadingPrevious]);
}
