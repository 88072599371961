/* eslint-disable no-redeclare */
import ReactDOM from "react-dom";
import { AlertModal } from "../components/General";
import i18n from "../i18n";
import React, { useLayoutEffect, useState } from "react";
import { ButtonWithLoader } from "../components/Buttons";
import { Button, Modal } from "react-bootstrap";
import { CustomIcon } from "../assets/icons";
import { useToggle } from "react-use";
import { MediaListViewer } from "../components/Media";
import { useTranslation } from "react-i18next";

export function alertPopup(arg: {
  title?: string;
  subtitle?: string;
  variant?: "success" | "error" | "warning";
  onClose?: () => void;
}): void {
  const container = document.createElement("div");
  document.body.appendChild(container);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
    arg.onClose?.();
  };
  const jsx = (
    <AlertModal
      title={arg.title || i18n.t("general.error")}
      subtitle={arg.subtitle}
      variant={arg.variant || "error"}
      onClose={onClose}
      show={true}
    />
  );
  ReactDOM.render(jsx, container);
}

export function alertError(title: undefined, subtitle: string): void;
export function alertError(title: string, subtitle?: string): void;
export function alertError(title?: string, subtitle?: string): void {
  alertPopup({
    title,
    subtitle,
    variant: "error",
  });
}

export function alertSuccess(title: undefined, subtitle: string): void;
export function alertSuccess(title: string, subtitle?: string): void;
export function alertSuccess(title?: string, subtitle?: string): void {
  alertPopup({
    title,
    subtitle,
    variant: "success",
  });
}

export function alertConfirm(
  callback: () => Promise<void>,
  title: string,
  subtitle: string
): void;
export function alertConfirm(
  callback: () => Promise<void>,
  title?: string,
  subtitle?: string
): void;
export function alertConfirm(
  callback: () => Promise<void>,
  title?: string,
  subtitle?: string
): void {
  const container = document.createElement("div");
  document.body.appendChild(container);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
  };
  const jsx = (
    <AlertModal
      title={title ?? i18n.t("warnings.are_you_sure_question")}
      subtitle={subtitle}
      variant="warning"
      onClose={onClose}
      show={true}
      buttons={(onClose) => {
        const [isLoading, setIsLoading] = useState(false);
        const [t] = useTranslation();
        const handleConfirm = () => {
          const callBackResult = callback();
          setIsLoading(true);
          callBackResult.finally(() => setIsLoading(false)).finally(onClose);
        };
        return (
          <React.Fragment>
            <ButtonWithLoader
              variant="warning"
              className="w-auto mr-2"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleConfirm();
              }}
              isLoading={isLoading}
            >
              {t("general.confirm")}
            </ButtonWithLoader>
            <Button
              variant="light"
              className="w-auto"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              {t("general.cancel")}
            </Button>
          </React.Fragment>
        );
      }}
    />
  );
  ReactDOM.render(jsx, container);
}

export function viewMediaListPopup(arg: {
  images: string | string[] | undefined | null;
  videos?: string | string[] | undefined | null;
  initialIdx?: number;
}): void {
  const { videos, images, initialIdx } = arg;
  if (!images) return;

  const container = document.createElement("div");
  document.body.appendChild(container);
  const onClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
  };
  const Component = () => {
    const [show, toggleShow] = useToggle(true);
    useLayoutEffect(() => {
      if (!show) setTimeout(onClose, 200);
    }, [show]);
    return (
      <Modal centered size="xl" show={show} onHide={onClose}>
        <Modal.Body className="text-center">
          <MediaListViewer
            images={images}
            videos={videos ?? null}
            initialIdx={initialIdx}
            viewOnClick={false}
          />
        </Modal.Body>
        <Button
          className="btn-icon modal-close"
          variant="warning"
          onClick={() => toggleShow(false)}
        >
          <CustomIcon.Close className="icon" />
        </Button>
      </Modal>
    );
  };
  ReactDOM.render(<Component />, container);
}
