import React, { useMemo } from "react";
import { AppHeader } from "./Header";
import { AppFooter } from "./Footer";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import classNames from "classnames";
import { Link, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";
import * as FeatherIcon from "react-feather";
import { handleLogout } from "../utils/auth";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { userApiHooks } from "../api/user";
import { getImageSrc } from "../utils/other";
import { Trans, useTranslation } from "react-i18next";
import { CookRating, ProfileImageWithUpload } from "./Widgets";
import { useLang, useMiniCallback, useUser } from "../other/hooks";
import { alertError } from "../utils/alerts";
import { ChatBox } from "./Chat";

export const Layout: React.FC<{
  pageId: string;
  className?: string;
}> = ({ children, pageId, className }) => {
  const lang = useLang();
  const user = useUser();
  const getUserImageUrl = useMiniCallback((src: string | null) =>
    getImageSrc(src, "profile")
  );
  return (
    <div className={classNames("app", "lang-" + lang)}>
      {!!user && <ChatBox getUserImageUrl={getUserImageUrl} userId={user.id} />}
      <AppHeader />
      <main className={classNames("page__middle", className)} id={pageId}>
        <div className={classNames("page__content")}>{children}</div>
      </main>
      <AppFooter />
    </div>
  );
};

export const UserPageLayout: React.FC<{
  replaceContent?: boolean;
  colClassnames?: [string?, string?, string?];
  showSidebar?: boolean;
}> = ({
  children = "",
  replaceContent = false,
  colClassnames,
  showSidebar = true,
}) => {
  const colCls = colClassnames;
  const name = useSelector((state: RootState) => state.user.user?.name);
  return (
    <div className="container">
      <Row className="row-48">
        <Col
          className={classNames(
            {
              "col-48 col-md-12 col-xl-9": !colCls?.[0],
              "pl-lg-0": true,
            },
            colCls?.[0]
          )}
        >
          <UserLeftSidebar />
        </Col>
        <Col
          className={classNames(
            {
              "col-48 col-md-32 col-xl-27": !colCls?.[1],
              "pl-md-3 mt-4.5 mt-md-0": true,
            },
            colCls?.[1]
          )}
        >
          {!replaceContent ? (
            <>
              <Trans
                i18nKey="user.greeting_text"
                values={{ name }}
                components={[
                  <span key={0} className="text-3" />,
                  <h1 key={1} className="h3" />,
                ]}
              />

              <UserTabs />

              <main className="mt-3.5 pt-4">{children}</main>
            </>
          ) : (
            <main>{children}</main>
          )}
        </Col>
        <Col
          className={classNames(
            {
              "col-sm-48 col-xl-12": !colCls?.[2],
              "text-right pr-0 pl-xl-2 mt-3 mt-xl-0": true,
            },
            colCls?.[2]
          )}
        >
          {showSidebar && <UserRightSidebar />}
        </Col>
      </Row>
    </div>
  );
};

const UserLeftSidebar = React.memo(() => {
  const [t] = useTranslation();
  const history = useHistory();
  const user = useUser();

  const navItems: {
    icon: JSX.Element;
    href: string;
    title: string;
  }[] = useMemo(
    () => [
      {
        icon: <FeatherIcon.User />,
        href: `/user/profile`,
        title: t("user_nav.profile"),
      },
      {
        icon: <FeatherIcon.Settings />,
        href: `/user/settings`,
        title: t("user_nav.settings"),
      },
    ],
    [t]
  );
  const { response: myRateResponse } = userApiHooks.useUserRate(user?.id, 1);
  return (
    <div>
      <ProfileImageWithUpload />
      <div className="text-center">
        <CookRating rating={myRateResponse?.total_rate} />
        {/* <div className="mt-2"> */}
        {/*  <img */}
        {/*    src="https://img.icons8.com/carbon-copy/100/000000/loyalty-card.png" */}
        {/*    height={16} */}
        {/*  /> */}
        {/*  <span>450.5</span> */}
        {/* </div> */}
      </div>
      <nav className="user-nav">
        {navItems.map((item) => (
          <Link
            key={item.href}
            to={item.href}
            className={classNames({
              active: item.href === history.location.pathname,
            })}
          >
            <span className="icon">{item.icon}</span>
            {item.title}
          </Link>
        ))}
        <hr />
        <Link
          to="/logout"
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
          }}
        >
          <span className="icon">
            <FeatherIcon.LogOut />
          </span>
          {t("user_nav.logout")}
        </Link>
      </nav>
    </div>
  );
});

const UserRightSidebar = React.memo(() => {
  const [t] = useTranslation();
  const user = useUser();
  const isCook = !!user?.is_cook;
  const history = useHistory();

  const onCreateDinnerClick = () => {
    if (!user?.identity_confirmed) {
      const title = t("warnings.dinner_creation_id_confirmation.title");
      const msg = t("warnings.dinner_creation_id_confirmation.message");
      alertError(title, msg);
      history.push("/user/settings");
    } else {
      history.push("/user/dinners/create");
    }
  };
  return (
    <Container className="pt-4 pt-xl-0 user-right-sidebar">
      <Row>
        {isCook && (
          <>
            <Col sm={12} md={6} lg={12}>
              <Button
                variant="primary"
                onClick={onCreateDinnerClick}
                className="w-100"
              >
                <span>{t("user.post_dinner_btn")}</span>
              </Button>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={12}
              className="mt-3 mt-md-0 my-lg-3 mb-3 mb-md-0"
            >
              <Link to="/calculator" className="btn btn-md btn-light w-100">
                <span>{t("user.dinner_calculator_btn")}</span>
              </Link>
            </Col>
          </>
        )}
        <Col sm={12} md={6} lg={12}>
          <Link to="/search" className="btn btn-md btn-warning w-100">
            <span>{t("user.find_dinner_btn")}</span>
          </Link>
        </Col>
      </Row>
    </Container>
  );
});

const UserTabs = React.memo(() => {
  const [t] = useTranslation();
  const history = useHistory();
  const isCook = useSelector((state: RootState) => state.user.user!.is_cook);
  const tabs = useMemo(() => {
    const result: { href: string; label: JSX.Element | string }[] = [
      {
        href: "/user/activity",
        label: t("user_nav.activity"),
      },
    ];
    if (isCook) {
      result.push({
        href: "/user/kitchen",
        label: t("user_nav.my_kitchen"),
      });
    }
    result.push(
      {
        href: "/user/recipes",
        label: t("user_nav.recipes"),
      },
      {
        href: "/user/orders",
        label: t("user_nav.orders"),
      },
      {
        href: "/user/followers",
        label: t("user_nav.followers"),
      }
    );
    return result;
  }, [isCook, t]);

  const matchedRoute = useRouteMatch(tabs.map((t) => t.href));
  const initialTab = tabs.find((tab) => tab.href === matchedRoute?.path);

  return (
    <Tabs
      className="profile-tabs"
      transition={false}
      onSelect={(tab) => history.push(tab!)}
      activeKey={initialTab?.href}
    >
      {tabs.map((tab) => (
        <Tab key={tab.href} eventKey={tab.href} title={tab.label} />
      ))}
    </Tabs>
  );
});
