import React, { useEffect, useMemo, useRef } from "react";
import { useMiniCallback, usePagination } from "../other/hooks";
import { useLatest } from "react-use";
import { MediaItem } from "../other/interfaces";
import { Pagination } from "./General";
import { CustomIcon } from "../assets/icons";
import classNames from "classnames";
import { viewMediaListPopup } from "../utils/alerts";

export const VideoContainer: React.FC<{
  src: string;
  videoClassname?: string;
  onPlayClick?: (e: React.MouseEvent) => void;
  videoProps?: {
    controls?: boolean;
    muted?: boolean;
    autoPlay?: boolean;
  };
}> = ({ src, videoClassname, videoProps, onPlayClick }) => {
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<HTMLVideoElement>(null);

  const onPlayStateChanged = (currentState: "playing" | "paused") => {
    if (!videoContainerRef.current) return;

    if (currentState === "playing") {
      videoContainerRef.current!.classList.add("playing");
      return;
    }
    videoContainerRef.current!.classList.remove("playing");
  };
  const togglePlay = () => {
    if (!playerRef.current) return;
    const { paused } = playerRef.current;
    if (paused) {
      playerRef.current!.play();
    } else {
      playerRef.current!.pause();
    }
  };

  return (
    <div className="video-container" ref={videoContainerRef}>
      <video
        src={src}
        ref={playerRef}
        className={videoClassname}
        onPlay={() => onPlayStateChanged("playing")}
        onPause={() => onPlayStateChanged("paused")}
        {...videoProps}
      />
      <CustomIcon.Play
        className="play-btn"
        onClick={onPlayClick || togglePlay}
      />
    </div>
  );
};
export const MediaListViewer = React.memo<{
  images: string | string[] | null;
  videos: string | string[] | null;
  itemClassname?: string;
  className?: string;
  initialIdx?: number;
  viewOnClick?: boolean;
}>(
  ({
    images,
    viewOnClick = true,
    videos,
    itemClassname,
    className,
    initialIdx,
  }) => {
    const pagination = usePagination();
    const paginationRef = useLatest(pagination);
    const mediaItems = useMemo(() => {
      const items: MediaItem[] = [];
      // Video
      if (videos) {
        if (!Array.isArray(videos)) {
          items.push({ type: "video", src: videos });
        } else {
          videos.forEach((src) => items.push({ type: "video", src }));
        }
      }

      // Image
      if (images) {
        if (!Array.isArray(images)) {
          items.push({ type: "image", src: images });
        } else {
          images.forEach((src) => items.push({ type: "image", src }));
        }
      }

      return items;
    }, [images, videos]);

    const currentItem = mediaItems[pagination.currentPage - 1] as
      | MediaItem
      | undefined;

    const viewMediaInPopup = useMiniCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      viewMediaListPopup({ images, videos });
    });
    const onMediaClick = viewOnClick ? viewMediaInPopup : undefined;

    // Reset state when items modified
    useEffect(() => {
      paginationRef.current.onPageChange(1);
      paginationRef.current.onPageCountChange(mediaItems.length);
    }, [mediaItems, paginationRef]);

    useEffect(() => {
      if (initialIdx) paginationRef.current.onPageChange(initialIdx + 1);
    }, [initialIdx, paginationRef]);
    return (
      <div className={classNames(className, "media-list-view")}>
        {mediaItems.length === 0 && (
          <img src="/assets/no-image.svg" className={itemClassname} alt="" />
        )}
        {currentItem && (
          <div onClick={onMediaClick} className="clickable">
            {currentItem.type === "video" && (
              <VideoContainer
                src={currentItem.src}
                videoClassname={itemClassname}
                onPlayClick={onMediaClick}
                videoProps={
                  !viewOnClick
                    ? { controls: true, muted: false, autoPlay: true }
                    : {}
                }
              />
            )}
            {currentItem.type === "image" && (
              <img src={currentItem.src} className={itemClassname} alt="" />
            )}
          </div>
        )}

        <Pagination
          {...pagination}
          pageRangeDisplayed={3}
          marginPagesDisplayed={4}
          iconsOnly
          dotted
          containerClassName="mb-0 mt-2.5"
        />
      </div>
    );
  }
);
