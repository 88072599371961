import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactSelect, {
  components as RSComponents,
  Props as ReactSelectProps,
} from "react-select";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import classNames from "classnames";
import { SelectOption } from "../../other/interfaces";
import { useMiniCallback } from "../../other/hooks";
import { useLatest, useList, useUpdateEffect } from "react-use";
import _ from "lodash";

type SelectProps<T = unknown> = Omit<
  ReactSelectProps,
  "value" | "onChange" | "onInputChange" | "options"
> & {
  filterByInput?: boolean;
  isInvalid?: boolean;
  creatable?: false;
  onCreate?: (v: SelectOption<string>) => Promise<SelectOption<T>>;
  onClearValue?: (e: React.MouseEvent) => void;
  options: SelectOption<T>[] | undefined;
} & (
    | {
        defaultValue?: T;
        value?: T;
        onChange?: (value: T) => void;
        isMulti?: false;
      }
    | {
        defaultValue?: T[];
        value?: T[];
        onChange?: (value: T[]) => void;
        isMulti: true;
      }
  );
export const Select = React.forwardRef<ReactSelect, SelectProps>(
  (
    {
      inputRef,
      onInputFocus,
      creatable,
      filterByInput,
      onClearValue,
      ...props
    },
    ref
  ) => {
    const [t] = useTranslation();
    const [inputTxt, setInputTxt] = useState("");
    const components = useComponents(inputTxt, onClearValue);
    const [internalValue, setInternalValue] = useState<
      SelectOption[] | undefined
    >([]);
    const internalValueSet = useRef(false);
    const [propsRef, internalValueRef] = [
      useLatest(props),
      useLatest(internalValue),
    ];
    const [createdOptions, createdOptionsFns] = useList<SelectOption<any>>([]);
    const createdOptionRef = useRef<SelectOption<any>>();
    const mustDispatch = useRef(false); // Prevents infinite render

    const options = useMemo(() => {
      return [...createdOptions, ...(props.options ?? [])];
    }, [createdOptions, props.options]) as typeof props.options;

    const onChange = useOnChangeHandler(
      useMiniCallback((newOption) => {
        return props.onCreate!(newOption).then((createdOption) => {
          createdOptionsFns.push(createdOption as any);
          createdOptionRef.current = createdOption;
          return createdOption;
        });
      }),
      useMiniCallback((value) => {
        mustDispatch.current = true;
        setInternalValue(value);
      })
    );

    const {
      optionsToRawValue,
      rawValueToOptions,
    } = useValueAndOptionTransforms(props.isMulti);

    // Set internal value by defaultValue
    useEffect(() => {
      if (!props.defaultValue || internalValueSet.current) return;
      const nextValue = rawValueToOptions(options, props.defaultValue);
      setInternalValue(nextValue);
      internalValueSet.current = true;
    }, [props.defaultValue, options, rawValueToOptions]);

    // Set internal value by external value
    useEffect(() => {
      if (props.value) {
        const nextValue = rawValueToOptions(options, props.value);
        const isEqual = _.isEqual(nextValue, internalValueRef.current);
        if (!isEqual) setInternalValue(nextValue);
      }
    }, [internalValueRef, options, props.value, rawValueToOptions]);

    // Dispatch internal value to outside
    useEffect(() => {
      if (internalValue && mustDispatch.current) {
        const nextValue = optionsToRawValue(internalValue);
        propsRef.current.onChange?.(nextValue as any);
        mustDispatch.current = false;
      }
    }, [internalValue, optionsToRawValue, propsRef]);

    // Push created option to value before options updated
    useEffect(() => {
      const createdOption = createdOptionRef.current;
      if (!createdOption) return;
      setInternalValue((internalValue) => [
        ...(internalValue ?? []),
        createdOption,
      ]);
      mustDispatch.current = true;
      createdOptionRef.current = undefined;
    }, [options]);

    const Component = (!creatable
      ? ReactSelect
      : Creatable) as typeof Creatable;
    return (
      <Component
        {...props}
        className={classNames(
          "react-select",
          props.className,
          props.isInvalid && "is-invalid",
          (filterByInput || creatable) && "allow-filter"
        )}
        options={options as ReactSelectProps["options"]}
        classNamePrefix="react-select"
        ref={ref as any}
        hideSelectedOptions={false}
        onInputChange={props.onInputChange || setInputTxt}
        components={components}
        value={internalValue}
        defaultValue={undefined}
        onChange={onChange}
        placeholder={t("general.select_placeholder")}
        formatCreateLabel={(text) =>
          t("general.select_create_option_label", { text })
        }
        onKeyDown={(e) => {
          if (!filterByInput && !creatable) {
            e.preventDefault();
            e.stopPropagation();
          }
          props.onKeyDown?.(e);
        }}
      />
    );
  }
);

const useValueAndOptionTransforms = (isMulti: boolean | undefined) => {
  const rawValueToOptions = useMiniCallback(
    (
      options: SelectProps<any>["options"],
      rawValue: any
    ): SelectOption<any>[] | undefined => {
      if (!options || !rawValue) return undefined;
      if (isMulti) {
        return options.filter((option: any) =>
          rawValue!.includes(option.value)
        );
      }
      return options.filter((option: any) => rawValue === option.value);
    }
  );
  const optionsToRawValue = useMiniCallback(
    (
      options:
        | NonNullable<SelectProps["options"]>
        | NonNullable<SelectProps["options"]>[number]
    ) => {
      if (!Array.isArray(options)) return options.value;
      return options.map((o) => o.value);
    }
  );
  return useMemo(
    () => ({
      rawValueToOptions,
      optionsToRawValue,
    }),
    [optionsToRawValue, rawValueToOptions]
  );
};

const useOnChangeHandler = (
  onCreate: SelectProps<any>["onCreate"],
  onChange: (v: SelectProps<any>["options"]) => void
): ReactSelectProps<{ label: string; value: string }, boolean>["onChange"] => {
  const handleFilterAndChange = useMiniCallback(
    (options: ReactSelectProps["onChange"]) => {
      // Skip created options
      if (Array.isArray(options)) {
        const dispatchValue = options.filter((o) => !o.__isNew__);
        return onChange(dispatchValue);
      }
      // Skip if created option
      if (!(options as any).__isNew__) onChange(options as any);
    }
  );
  return useMiniCallback((options, action) => {
    if (!options) return;
    // Create
    if (action.action === "create-option" && onCreate) {
      return onCreate(action.option);
    }
    // Change
    handleFilterAndChange(options);
  });
};

const useComponents = (
  inputTxt: string,
  onValueClear: ((e: React.MouseEvent) => void) | undefined
) =>
  useMemo<typeof RSComponents>(() => {
    return {
      ...RSComponents,
      ClearIndicator: (props) => {
        return (
          <RSComponents.ClearIndicator
            {...props}
            innerProps={{
              ...props.innerProps,
              onClick: (e: React.MouseEvent) => {
                onValueClear?.(e);
              },
            }}
          />
        );
      },
      Option: (props) => {
        let children: React.ReactNode[] = [props.children];
        const label: string = props.data.label;

        // Highlight matched part of label
        const parts = label.split(inputTxt);
        const isNew = props.data.__isNew__;
        if (!isNew && parts.length > 1 && label.length > inputTxt.length) {
          const matchIdx = label.indexOf(inputTxt);
          children = parts as React.ReactNode[];
          children.splice(
            +matchIdx + 1,
            0,
            <span className="text-highlight" key={inputTxt}>
              {inputTxt}
            </span>
          );
        }
        return <RSComponents.Option {...props}>{children}</RSComponents.Option>;
      },
    };
  }, [inputTxt, onValueClear]);
