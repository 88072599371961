import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { DinnerType } from "./dinner.validators";
import { SelectOption } from "../../other/interfaces";

export const useUnitTypeOptions = () => {
  const [t] = useTranslation();
  return useMemo(() => {
    return [
      {
        label: t("general.dinner_unit_type.portion"),
        value: "portion",
      },
      {
        label: t("general.dinner_unit_type.piece"),
        value: "piece",
      },
    ];
  }, [t]);
};
export const useDinnerTypeOptions = () => {
  const [t] = useTranslation();
  return useMemo(() => {
    return [
      {
        label: t("general.dinner_type.hot"),
        value: DinnerType.Hot,
      },
      {
        label: t("general.dinner_type.cold"),
        value: DinnerType.Cold,
      },
    ];
  }, [t]);
};
export const useCookingTimeOptions = () => {
  const [t] = useTranslation();
  return useMemo(() => {
    const values = [15, 30, 45];
    const options: SelectOption<number>[] = values.map((value) => ({
      label: `${value} ${t("general.minutes_short")}`,
      value,
    }));
    return options;
  }, [t]);
};
