import Joi from "joi";
import { overrideJoiCode } from "../utils/other";

export const commonFieldSchemas = {
  name: Joi.string().min(2).max(30),
  lastname: Joi.string().min(2).max(30),
  email: Joi.string().email({ tlds: { allow: false } }),
  mobile: Joi.string().error(
    overrideJoiCode({ "string.pattern.base": "mobile.string.pattern.base" })
  ),
  address: Joi.object({
    address: Joi.string().min(5).required(),
    lat: Joi.number().required(),
    lng: Joi.number().required(),
  }),
  password: Joi.string().min(4).max(50),
  iban: Joi.string().min(1),
};
