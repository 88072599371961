export function jsxJoin(
  array: Array<string | number | JSX.Element | null | undefined>,
  glue: JSX.Element | string
) {
  return array.length > 0
    ? array
        .filter((i) => i === 0 || !!i)
        .reduce((result, item) => (
          <>
            {result}
            {glue}
            {item}
          </>
        ))
    : null;
}

interface Action {
  type: string;
  payload?: unknown;
}

export const getActions = <T extends Action>() => <
  R extends Record<string, (...args: any) => T> = Record<string, any>
>(
  actions: R
): {
  [P in keyof R]: (...args: Parameters<R[P]>) => T;
} => actions;
