import Joi from "joi";
import { commonFieldSchemas } from "../../../other/common.validators";

export interface BecomeIBANForm {
  iban: string;
  id_number: string;
}

export const becomeCookFormSchema: Joi.ObjectSchema<BecomeIBANForm> = Joi.object(
  {
    iban: commonFieldSchemas.iban.required(),
    id_number: Joi.string().required(),
  }
);
