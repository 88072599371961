import { useGet, useHttp } from "../other/use-http";
import {
  Country,
  MealCategory,
  MealIngredient,
  PropByLang,
  UploadedFile,
} from "../other/interfaces";
import { useEffect, useMemo, useState } from "react";
import { useLatest } from "react-use";
import _ from "lodash";
import { DeliveryMethodBase } from "../pages/Dinner/dinner.validators";
import ms from "ms";
import { CachePolicies } from "use-http";

export interface ContactRequest {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface ContactSettings {
  id: number;
  email: string;
  phone: string;
  address_ge: string;
  address_en: string;
  address_ru: string;
  contact_email_for_form: string;
  facebook: string;
  twitter?: any;
  instagram?: any;
  youtube?: any;
  created_at?: any;
  updated_at: string;
  deleted_at?: any;
  linkedin: string;
  telegram: string;
  desc?: any;
  address: string;
}
export type GetReportCausesResponse = { id: number; name: string }[];

export type HowItWorksResponse = Array<{
  section: string;
  title: string;
  image: string;
  desc: string;
  button: string;
  second_title: string;
  option_one: string;
  option_two: string;
  option_three: string;
  option_four: string;
}>;

export type HomePageResponse = {
  image: string;
  title: string;
  section_one_title: string;
  section_one_title_second?: any;
  section_one_desc: string;
  section_one_button: string;
  section_one_image: string;
  section_two_title: string;
  section_two_title_second?: any;
  section_two_desc: string;
  section_two_button: string;
  section_two_image: string;
};

export type TermsAndConditionsResponse = {
  desc: string;
};

export const generalApiHooks = {
  useCountries: () => {
    const [countries, setCountries] = useState<Country[]>();

    const http = useHttp<{ get: [undefined, Country[]] }>("/public/country", {
      suspense: true,
      cacheLife: ms("2 min"),
      persist: true,
      cachePolicy: CachePolicies.CACHE_FIRST,
    });
    const httpRef = useLatest(http);

    useEffect(() => {
      httpRef.current.get(undefined).then(setCountries);
    }, [httpRef]);

    if (http.error) throw http.error;
    return useMemo(() => ({ countries, isLoading: http.loading }), [
      countries,
      http.loading,
    ]);
  },

  useCreateMealCategory: () =>
    useHttp<{ post: [{ name: string }, MealCategory] }>(
      "/user/meal-categories"
    ),

  useMealCategories: () => {
    const http = useHttp<{ get: [undefined, MealCategory[]] }>(
      "/public/meal-categories",
      {
        suspense: true,
        // cacheLife: ms("2 min"),
        // persist: true,
        // cachePolicy: CachePolicies.CACHE_FIRST,
      }
    );
    return useGet(http, undefined, "categories", {
      refreshOnLanguageChange: true,
    });
  },
  useMealCategoryById: () => {
    const { categories, isLoading } = generalApiHooks.useMealCategories();

    return useMemo(() => {
      const mealCategoryById:
        | Record<string, MealCategory>
        | undefined = categories
        ? _.mapValues(_.groupBy(categories, "id"), "0")
        : undefined;
      return {
        isLoading,
        mealCategoryById,
      };
    }, [categories, isLoading]);
  },

  useDeliveryMethods: () => {
    const http = useHttp<{ get: [void, DeliveryMethodBase[]] }>(
      "/public/delivery-methods",
      {
        suspense: true,
        // cacheLife: ms("2 min"),
        // persist: true,
        // cachePolicy: CachePolicies.CACHE_FIRST,
      }
    );

    return useGet(http, undefined, "deliveryMethods", {
      refreshOnLanguageChange: true,
    });
  },
  useDeliveryMethodById: () => {
    const { deliveryMethods, isLoading } = generalApiHooks.useDeliveryMethods();

    return useMemo(() => {
      const deliveryMethodById:
        | Record<string, DeliveryMethodBase>
        | undefined = deliveryMethods
        ? _.mapValues(_.groupBy(deliveryMethods, "id"), "0")
        : undefined;
      return {
        isLoading,
        deliveryMethodById,
      };
    }, [deliveryMethods, isLoading]);
  },

  useReportCauses: () => {
    const http = useHttp<{ get: [void, GetReportCausesResponse] }>(
      "/public/report-causes",
      {
        suspense: true,
        cacheLife: ms("2 min"),
        persist: true,
        cachePolicy: CachePolicies.CACHE_FIRST,
      }
    );

    return useGet(http, undefined, "causes");
  },

  useCreateMealIngredient: () =>
    useHttp<{ post: [{ name: string }, MealIngredient] }>(
      "/user/meal-ingredients"
    ),
  useMealIngredients: () => {
    const http = useHttp<{ get: [undefined, MealIngredient[]] }>(
      "/public/meal-ingredients",
      {
        suspense: true,
        // cacheLife: ms("2 min"),
        // persist: true,
        cachePolicy: CachePolicies.CACHE_FIRST,
      }
    );
    return useGet(http, undefined, "ingredients", {
      refreshOnLanguageChange: true,
    });
  },
  useMealIngredientById: () => {
    const { ingredients, isLoading } = generalApiHooks.useMealIngredients();

    return useMemo(() => {
      const ingredientById:
        | Record<string, MealIngredient>
        | undefined = ingredients
        ? _.mapValues(_.groupBy(ingredients, "id"), "0")
        : undefined;
      return {
        isLoading,
        ingredientById,
      };
    }, [ingredients, isLoading]);
  },

  useMarkWelcomeSeen: () => useHttp<{ put: [void, void] }>("/user/welcome"),
  useContact: () =>
    useHttp<{ post: [ContactRequest, void] }>("/public/contact"),
  useContactInformation: () => {
    const http = useHttp<{ get: [void, ContactSettings] }>(
      "/public/contact/settings"
    );
    return useGet(http, undefined, "settings");
  },
  usePhotoUpload: (resource: "dinners" | "recipes") =>
    useHttp<{ post: [FormData, UploadedFile] }>(
      `/user/media/${resource}/photo`
    ),
  useVideoUpload: (resource: "dinners" | "recipes") =>
    useHttp<{ post: [FormData, UploadedFile] }>(
      `/user/media/${resource}/video`
    ),

  useFAQ: () => {
    const http = useHttp<{
      get: [void, Array<{ question: string; answer: string }>];
    }>("/public/faq", {
      cacheLife: ms("1h"),
      suspense: true,
    });
    return useGet(http, undefined);
  },

  useAboutUs: () => {
    const http = useHttp<{
      get: [void, { desc: string; file: string | null }];
    }>("/public/about", {
      suspense: true,
    });
    return useGet(http, undefined, undefined, {
      refreshOnLanguageChange: true,
    });
  },

  useHowItWorks: () => {
    const http = useHttp<{
      get: [void, HowItWorksResponse];
    }>("/public/how_it_works", {
      suspense: true,
    });
    return useGet(http, undefined, undefined, {
      refreshOnLanguageChange: true,
    });
  },

  useTermsAndConditions: () => {
    const http = useHttp<{
      get: [void, TermsAndConditionsResponse];
    }>("/public/terms", {
      suspense: true,
    });
    return useGet(http, undefined, undefined, {
      refreshOnLanguageChange: true,
    });
  },

  useHomePage: () => {
    const http = useHttp<{
      get: [void, HomePageResponse];
    }>("/public/firstpage", {
      suspense: true,
    });
    return useGet(http, undefined, undefined, {
      refreshOnLanguageChange: true,
    });
  },
};
