import React from "react";
import "../assets/styles/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { generalApiHooks } from "../api/general";
import { Link } from "react-router-dom";

export const AppFooter: React.FC = () => {
  const [t] = useTranslation();
  const { settings } = generalApiHooks.useContactInformation();
  if (!settings) return null;
  return (
    <footer>
      <div className="container p-lg-0 text-dark">
        <div className="d-flex justify-content-between align-items-center flex-column flex-lg-row">
          <div>
            <div className="d-flex flex-column flex-md-row align-items-center">
              <span className="mr-md-2">{t("footer.follow_us_text")}</span>
              {/* NOTE: Display facebook and instagram ONLY */}
              <nav id="socials" className="mt-2.5 mt-md-0">
                {settings.facebook && (
                  <a href={settings.facebook}>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                )}
                {settings.instagram && (
                  <a href={settings.instagram}>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                )}
              </nav>
            </div>
          </div>
          <div className="mt-3 mt-md-0">
            <div className="d-flex flex-column flex-md-row align-items-center">
              <span className="mr-md-4">{t("footer.get_app_text")}</span>
              <nav id="apps" className="mt-2.5 mt-md-0">
                <a href="#" className="btn btn-light btn-with-icon disabled">
                  <FontAwesomeIcon icon={faApple} />
                  <span>App Store</span>
                </a>
                <a href="#" className="btn btn-light btn-with-icon disabled">
                  <FontAwesomeIcon icon={faPlay} />
                  <span>Play Store</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="container p-lg-0">
        <nav className="footer-menu">
          <Link to="/faq">
            <span>{t("footer.menu.faq")}</span>
          </Link>
          <Link to="/terms-and-conditions">
            <span>{t("footer.menu.terms_and_conditions")}</span>
          </Link>
          <Link to="/contact">
            <span>{t("footer.menu.contact")}</span>
          </Link>
        </nav>
      </div>
      <hr />
      <div className="container p-lg-0 pt-3">
        <div className="text-gray mt-3">
          <div className="d-flex justify-content-between align-items-center flex-column-reverse flex-md-row">
            <p className="copyright mt-2.5 mt-md-0">
              <Trans
                i18nKey="footer.copyright_text"
                values={{ year: new Date().getFullYear() }}
              />
            </p>
            <div>
              <nav id="payment-methods" className="mt-2.5 mt-sm-0">
                <FontAwesomeIcon icon={faCcVisa} />
                <FontAwesomeIcon icon={faCcMastercard} />
                <FontAwesomeIcon icon={faCcAmex} />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
