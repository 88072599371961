import { produce } from "immer";
import { UserRead } from "../../other/interfaces";
import { UserAction } from "./actions";

interface UserState {
  user: UserRead | undefined;
  emailCodeSentAt: Date | undefined;
  mobileCodeSentAt: Date | undefined;
  allergiesSetupSkipped: boolean | undefined;
  interestsSetupSkipped: boolean | undefined;
}

const initialState: UserState = {
  user: undefined,
  emailCodeSentAt: undefined,
  mobileCodeSentAt: undefined,
  allergiesSetupSkipped: undefined,
  interestsSetupSkipped: undefined,
};

export const userReducer = (state = initialState, action: UserAction) =>
  produce(state, (nextState) => {
    switch (action.type) {
      case "LOGIN":
        nextState.user = action.payload as any;
        break;
      case "EMAIL_CODE_SEND":
        nextState.emailCodeSentAt = new Date();
        break;
      case "VERIFY_EMAIL":
        nextState.user!.verify_email = true;
        break;
      case "SKIP_EMAIL_VERIFY":
        nextState.user!.verify_email = -1;
        break;
      case "VERIFY_MOBILE":
        nextState.user!.verify_mobile = true;
        break;
      case "MOBILE_CODE_SEND":
        nextState.mobileCodeSentAt = new Date();
        break;
      case "SEE_WELCOME":
        nextState.user!.welcome_seen = true;
        break;
      case "SKIP_INTERESTS_SETUP":
        nextState.interestsSetupSkipped = true;
        break;
      case "SKIP_ALLERGIES_SETUP":
        nextState.allergiesSetupSkipped = true;
        break;
      case "BECOME_COOK":
        if (nextState.user) {
          nextState.user.iban = action.payload.iban;
          nextState.user.id_number = action.payload.id_number;
          nextState.user.is_cook = 1;
        }

        break;
      case "BECOME_GOURMET":
        nextState.user!.is_cook = 0;
        break;
      case "UPDATE_PROFILE":
        nextState.user = {
          ...nextState.user,
          ...action.payload,
        } as UserRead;
        break;
      case "COMPLETE_PROFILE":
        nextState.user!.complited = "complited";
        break;
      case "CHANGE_PHOTO":
        nextState.user!.profile_image = action.payload;
        break;
      case "LOGOUT":
        nextState.user = undefined;
        nextState.allergiesSetupSkipped = undefined;
        nextState.interestsSetupSkipped = undefined;
        nextState.emailCodeSentAt = undefined;
        break;
      default:
        return state;
    }
  });
