import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLatest, useList, useToggle } from "react-use";
import _ from "lodash";
import { useMiniCallback } from "../other/hooks";
import { ALLOWED_PHOTO_MIMES, UPLOAD_IMAGE_DIMENSIONS } from "../config";
import { alertError, viewMediaListPopup } from "../utils/alerts";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import { Area, Point, Size } from "react-easy-crop/types";
import { CustomIcon, FeatherIcon } from "../assets/icons";
import { ButtonWithIcon, ButtonWithLoader } from "./Buttons";
import { Button, Modal } from "react-bootstrap";
import { NumberInputWithSteps } from "./Inputs";
import { generalApiHooks } from "../api/general";
import {
  getCropSize,
  getImageDetailsAsync,
  getImageSrc,
  handleUnknownError,
} from "../utils/other";
import { UploadedFile } from "../other/interfaces";
import { Pagination } from "./General";

export type ImageUploadWithWithCropProp = {
  resource: "dinners" | "recipes";
} & (
  | {
      multiple?: false;
      onChange?: (v: string) => void;
      maxFiles?: undefined;
      initialValue?: string;
    }
  | {
      multiple: true;
      onChange?: (v: string[]) => void;
      maxFiles: number;
      initialValue?: string[];
    }
);
export const MultiUploadWithWithCrop = React.memo<ImageUploadWithWithCropProp>(
  React.forwardRef((props, ref) => {
    const { multiple, initialValue, resource, maxFiles = 1 } = props;
    const propsRef = useLatest(props);

    // Convert external value to internal
    const [uploadedFiles, uploadedFilesFns] = useList<UploadedFile>(() => {
      if (!initialValue) return [];
      if (Array.isArray(initialValue))
        return initialValue.map((fileName) => ({ file_name: fileName }));
      return [{ file_name: initialValue }];
    });

    const uploadReq = generalApiHooks.usePhotoUpload(props.resource);
    const uploader = useMiniCallback((data: FormData) => {
      return uploadReq.post(data).then((file) => {
        uploadedFilesFns.push(file);
        return file;
      });
    });
    const uploadBtnJSX = useMemo(
      () => <ImageUploaderWithCrop uploader={uploader} resource={resource} />,
      [resource, uploader]
    );

    // Dispatch internal value to outside
    useEffect(() => {
      const toDispatchValue = multiple
        ? uploadedFiles.map((f) => f.file_name)
        : uploadedFiles[0].file_name;

      propsRef.current.onChange?.(toDispatchValue as any);
    }, [multiple, propsRef, uploadedFiles]);

    return (
      <UploadedImagesList
        maxFiles={maxFiles}
        uploadBtnJSX={uploadBtnJSX}
        resource={resource}
        files={uploadedFiles}
        onFileRemove={uploadedFilesFns.removeAt}
        multiple={!!multiple}
      />
    );
  })
);

export type ImageUploaderProps = {
  resource: "dinners" | "recipes" | "profile";
  children?: (arg: { labelFor: string }) => React.ReactNode;
  uploader: (data: FormData) => Promise<UploadedFile>;
  autoSubmit?: boolean;
  innerRef?: React.MutableRefObject<{
    submit: () => Promise<void>;
  }>;
  onUploadPropsChange?: (arg: Area & { zoom: number; file: File }) => void;
};
export const ImageUploaderWithCrop: React.FC<ImageUploaderProps> = ({
  resource,
  uploader,
  children,
  autoSubmit = true,
  innerRef,
  onUploadPropsChange,
}) => {
  const [t] = useTranslation();
  const id = useMemo(() => _.uniqueId(), []);

  const [showModal, toggleModal] = useToggle(false);
  const [file, setFile] = useState<File>();
  const [imageDimensions, setImageDimensions] = useState<Size>();
  const fileRef = useRef<HTMLInputElement>(null);
  const uploadConfig = useRef<Area & { zoom: number }>();

  const { min: minDimensions, max: maxDimensions } = UPLOAD_IMAGE_DIMENSIONS[
    resource
  ];

  const cropSize = useMemo(() => {
    if (!imageDimensions) return maxDimensions;
    return getCropSize(imageDimensions, minDimensions, maxDimensions);
  }, [imageDimensions, maxDimensions, minDimensions]);

  const discardFile = useMiniCallback((reason: "mimeType" | "dimensions") => {
    if (reason === "mimeType") {
      alertError(
        undefined,
        t("warnings.invalid_file_type", {
          mime_types: ALLOWED_PHOTO_MIMES.join(", "),
        })
      );
    } else if (reason === "dimensions") {
      alertError(
        undefined,
        t("warnings.invalid_image_dimensions", minDimensions)
      );
    }
  });
  const acceptFile = useMiniCallback((dimensions: Size) => {
    const file = fileRef.current?.files?.[0];
    setFile(file);
    toggleModal(true);
    setImageDimensions(dimensions);
  });
  const handleChange = useMiniCallback(async () => {
    const file = fileRef.current?.files?.[0]!;
    // Check mime type & sizes, TODO enable mime type checking
    const isAllowedMimeType = true; // ALLOWED_PHOTO_MIMES.includes(file.type);
    const dim = await getImageDetailsAsync(file);
    const hasEnoughDimensions =
      dim.width >= minDimensions.width && dim.height >= minDimensions.height;
    // Discard or accept
    if (!isAllowedMimeType) discardFile("mimeType");
    else if (!hasEnoughDimensions) discardFile("dimensions");
    else acceptFile(dim);
    if (fileRef.current) fileRef.current.value = "";
  });

  const onClose = () => {
    setFile(undefined);
    toggleModal(false);
  };

  const handleUpload = useMiniCallback(() => {
    if (!uploadConfig.current)
      throw new Error("Invalid call, upload config not found");
    if (!file)
      throw new Error("Invalid call of handleUpload, file is undefined");
    const { x, y, zoom, height, width } = uploadConfig.current;
    const data = new FormData();
    data.append("file", file);
    data.append("crop.x", x.toString());
    data.append("crop.y", y.toString());
    data.append("crop.width", width.toString());
    data.append("crop.height", height.toString());
    data.append("crop.zoom", zoom.toString());

    return uploader(data)
      .then(onClose)
      .catch((err) => {
        handleUnknownError(err);
      });
  });

  const handleCropConfirm = useMiniCallback(
    async (area: Area, zoom: number) => {
      const conf = { ...area, zoom };
      uploadConfig.current = conf;
      onUploadPropsChange?.({ ...conf, file: file! });
      if (!autoSubmit) {
        toggleModal(false);
        return;
      }
      await handleUpload();
    }
  );
  if (innerRef) {
    innerRef.current = {
      submit: handleUpload,
    };
  }
  return (
    <React.Fragment>
      <input
        type="file"
        hidden
        id={id}
        ref={fileRef}
        onChange={handleChange}
        accept="image/*"
      />
      {children ? (
        children({ labelFor: id })
      ) : (
        <ButtonWithLoader variant="white" className="w-100 p-0">
          <label className="m-0 btn-with-icon" htmlFor={id}>
            <span>{t("general.image")}</span>
            <FeatherIcon.Image />
          </label>
        </ButtonWithLoader>
      )}

      {file && showModal && (
        <CropModal
          cropSize={cropSize}
          file={file}
          onConfirm={handleCropConfirm}
          onClose={onClose}
        />
      )}
    </React.Fragment>
  );
};

type ImagesDisplayProps = {
  resource: ImageUploaderProps["resource"];
  uploadBtnJSX: React.ReactNode;
  files: UploadedFile[];
  maxFiles: number;
  onFileRemove: (idx: number) => void;
  multiple: boolean;
};
const UploadedImagesList = React.memo<ImagesDisplayProps>((props) => {
  const { files, multiple, maxFiles } = props;
  const { resource, uploadBtnJSX } = props;
  const [idx, setIdx] = useState(0);
  const fileNames = files?.map((f) => f.file_name);

  return (
    <div className="upload-box photo-upload">
      {files.length ? (
        <div className="uploaded-files">
          <div className="uploaded-file">
            <button
              className="btn remove-btn"
              type="button"
              onClick={() => props.onFileRemove(idx)}
            >
              <FeatherIcon.Trash className="icon" />
            </button>
            <img
              src={getImageSrc(fileNames[idx], resource)}
              alt=""
              className="clickable"
              onClick={() =>
                viewMediaListPopup({
                  images: getImageSrc(fileNames, resource),
                  initialIdx: idx,
                })
              }
            />
          </div>
          {files.length > 1 && (
            <Pagination
              pageCount={files.length}
              pageRangeDisplayed={3}
              marginPagesDisplayed={4}
              pageLabelBuilder={() => "●"}
              onPageChange={(page) => setIdx(page - 1)}
              forcePage={idx + 1}
              iconsOnly
              activeClassName="active"
            />
          )}
          {multiple && files.length < maxFiles && (
            <div className="mt-3">{uploadBtnJSX}</div>
          )}
        </div>
      ) : (
        uploadBtnJSX
      )}
    </div>
  );
});

const CropModal = React.memo<{
  onClose: () => void;
  cropSize: Size;
  file: File;
  onConfirm: (croppedAreaPixels: Area, zoom: number) => Promise<void>;
}>(({ onClose, cropSize, file, onConfirm }) => {
  const [t] = useTranslation();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [isLoading, setLoading] = useToggle(false);
  const [imgDetails, setImgDetails] = useState<{
    width: number;
    height: number;
    url: string;
  }>();
  const croppedAreaRef = useRef<Area>();

  const onCropComplete = useMiniCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      console.debug("DEBUG croppedArea", croppedArea);
      console.debug("DEBUG croppedAreaPixels", croppedAreaPixels);
      croppedAreaRef.current = croppedAreaPixels;
    }
  );
  const handleConfirm = useMiniCallback(async () => {
    if (croppedAreaRef.current) {
      setLoading(true);
      await onConfirm(croppedAreaRef.current, zoom);
      setLoading(false);
    }
  });

  // Convert blob to string
  useEffect(() => {
    let isCancelled = false;
    setTimeout(async () => {
      if (file) {
        const details = await getImageDetailsAsync(file);
        if (!isCancelled) setImgDetails(details);
      }
    }, 0);
    return () => {
      isCancelled = false;
    };
  }, [file]);

  // calculate crop area size & change image view dimensions proportionately to avoid huge crop area
  const { cropAreaSize, cropAreaDelimiter, mediaProps } = useMemo(() => {
    if (!imgDetails) return { cropAreaSize: null, cropAreaDelimiter: null };

    const cropAreaHeight = Math.min(400, cropSize.height);
    const aspectRatio = cropSize.width / cropSize.height;
    const cropWidth = cropAreaHeight * aspectRatio;
    const cropAreaDelimiter = cropSize.height / cropAreaHeight;

    const mediaProps: Pick<CSSProperties, "width" | "height"> = {
      width: imgDetails.width / cropAreaDelimiter,
      height: imgDetails.height / cropAreaDelimiter,
    };
    return {
      cropAreaSize: { width: cropWidth, height: cropAreaHeight },
      cropAreaDelimiter,
      mediaProps,
    };
  }, [cropSize, imgDetails]);

  if (!cropAreaSize || !cropAreaDelimiter) return null;

  return (
    <Modal show centered size="lg" onHide={() => {}}>
      <Modal.Header>
        <Button
          className="btn-icon modal-close"
          variant="warning"
          onClick={() => {
            croppedAreaRef.current = undefined;
            onClose();
          }}
        >
          <CustomIcon.Close className="icon" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: 400 }}>
          <Cropper
            image={imgDetails?.url}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropSize={cropAreaSize}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between flex-1">
          <ButtonWithIcon
            variant="success"
            icon={<FeatherIcon.Upload />}
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            {t("general.upload")}
          </ButtonWithIcon>
          <div className="d-flex align-items-center ml-4">
            <span className="mr-3">{t("general.zoom")}</span>
            <NumberInputWithSteps
              onChange={setZoom}
              defaultValue={zoom}
              value={zoom}
              step={0.25}
              min={0.25}
              size="sm"
              formatValue={(v) => v + "x"}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
