import React, { useEffect, useRef, useState } from "react";
import "./chat.scss";
import {
  ChatContact,
  ChatMessage,
  ChatPendingMessage,
} from "./chat.interfaces";
import { useAudio, useLatest, usePrevious } from "react-use";
import classNames from "classnames";
import {
  ChatBoxDispatcher,
  isPendingMessage,
  useChatState,
} from "./chat.helpers";
import {
  useChatContacts,
  useDetectAndMarkSeenMessages,
  useInitChatService,
  useListenForMessages,
  useListenMessageSeenByParticipant,
  useMessagesInfiniteScroll,
  useMessagesList,
  useToggleChatBox,
} from "./chat.hooks";
import { ChatService } from "./chat.service";
import Countdown from "react-countdown";

interface ChatBoxProps {
  getUserImageUrl: (url: string | null) => string;
  userId: number;
}
export const ChatBox: React.FC<ChatBoxProps> = function ChatBox(props) {
  const chatState = useChatState();
  const chatStateRef = useLatest(chatState);

  const { contacts } = useChatContacts();
  const chatElement = React.useRef<HTMLDivElement | null>(null);

  const chatServiceInit = useInitChatService();
  const unseenCountTotal = Object.values(
    chatState.unseenCountByRoom.get()
  ).reduce((acc, curr) => acc! + curr!, 0);

  const [chatSoundAudio, state, chatSound] = useAudio({
    src: "/assets/chat-message.mp3",
    autoPlay: false,
  });

  // Update unseen messages count on when contacts loaded
  useEffect(() => {
    if (contacts.length) {
      chatStateRef.current.unseenCountByRoom.merge((current) => {
        const nextValue = { ...current };
        contacts.forEach((contact) => {
          if (nextValue[contact.room])
            nextValue[contact.room] = contact.unseenMessagesCount;
        });
        return nextValue;
      });
    }
  }, [chatStateRef, contacts]);

  // Update global state when props changed
  useEffect(() => {
    chatState.merge({
      getUserImageUrl: props.getUserImageUrl,
      userId: props.userId,
      initError: chatServiceInit.error,
      initialized: chatServiceInit.isInitialized,
    });
  }, [chatServiceInit, chatState, props]);

  useListenForMessages({
    onMessage: (message: ChatMessage) => {
      chatSound.play();
      chatState.unseenCountByRoom
        .nested(message.room)
        .set((count) => (count || 0) + 1);
    },
  });

  const toggleChat = useToggleChatBox(chatElement);

  useEffect(() => {
    ChatBoxDispatcher.onStartChat((userId) => {
      const contact = contacts.find((c) => c.user.id === userId);
      if (contact) {
        chatState.currentContact.set(contact);
        toggleChat(true);
      }
    });
  }, [chatState.currentContact, contacts, toggleChat]);

  return (
    <React.Fragment>
      {chatSoundAudio}

      {!!contacts?.length && (
        <div className={`chat-box closed`} ref={chatElement}>
          <div className="close-chat" onClick={() => toggleChat(false)}>
            x
          </div>
          <ChatContacts contacts={contacts} />

          <div className="chat-preview" onClick={() => toggleChat(true)}>
            <img src="/assets/chat.svg" width={50} height={50} alt="" />
            {!!unseenCountTotal && (
              <span className="badge">
                {unseenCountTotal > 9 ? "9+" : unseenCountTotal}
              </span>
            )}
          </div>
          <div className="chat">
            {(chatServiceInit.loading || chatServiceInit.error) && (
              <div className="chat-status">
                {chatServiceInit.loading && "Loading"}
                {chatServiceInit.error && "Error: " + chatServiceInit.error}
              </div>
            )}

            <ChatHeader />
            <ChatConversation />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const ChatHeader = React.memo(function ChatHeader() {
  const chatState = useChatState();
  const [contact, getUserImageUrl] = [
    chatState.currentContact.get(),
    chatState.getUserImageUrl.get(),
  ];

  // Render
  if (!contact) return null;
  const { user } = contact;
  const backgroundImage = `url(${getUserImageUrl(user.image)})`;
  return (
    <div className="contact bar">
      <div className="pic" style={{ backgroundImage }} />
      <div className="name">{user.name + " " + user.lastname}</div>
      {contact.expiryDate && (
        <div className="time-left">
          Time left: <Countdown date={new Date(contact.expiryDate)} />
        </div>
      )}
    </div>
  );
});

const ChatContacts = React.memo<{
  contacts: ChatContact[];
}>(function ChatContacts({ contacts }) {
  const chatState = useChatState();
  const [currentContact, getUserImageUrl, unseenMessagesByRoom] = [
    chatState.currentContact.get(),
    chatState.getUserImageUrl.get(),
    chatState.unseenCountByRoom.get(),
  ];

  // Render
  const { user } = currentContact ?? {};
  const backgroundImage = `url(${getUserImageUrl(user?.image || null)})`;
  return (
    <div className="contacts">
      <i className="fas fa-bars fa-2x" />
      <h2>Contacts</h2>
      {contacts.map((contact) => {
        const { user, room } = contact;
        const isSelected = contact === currentContact;
        const unseenMessagesCount = unseenMessagesByRoom[room];
        return (
          <div
            className={classNames("contact", isSelected && "active")}
            key={user.id}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              chatState.currentContact.set(contact);
            }}
          >
            <div className="pic" style={{ backgroundImage }} />
            {!!unseenMessagesCount && (
              <div className="badge">{unseenMessagesCount}</div>
            )}
            <div className="name">{user.name + " " + user.lastname}</div>
            {contact.expiryDate && (
              <div className="time-left">
                Time left: <Countdown date={new Date(contact.expiryDate)} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
});

const ChatConversation = React.memo(function ChatConversation() {
  const chatState = useChatState();
  const [contact] = [chatState.currentContact.get()];

  const {
    messages,
    setMessages,
    pushMessage,
    onPreviousPage,
    page,
    onMessageDelivered,
    pushPendingMessage,
    onMessageFailed,
  } = useMessagesList(contact?.room);
  const messagesListRef = useRef<HTMLDivElement | null>(null);

  const scrollMessagesList = (
    behavior: "smooth" | "auto" = "smooth",
    fraction = 1
  ) => {
    const container = messagesListRef.current;
    if (container) {
      const scrollOpts: ScrollToOptions = {
        top: container.scrollHeight * fraction,
        left: 0,
      };
      scrollOpts.behavior = behavior;
      container.scroll(scrollOpts);
    }
  };

  // Scroll when messages loaded
  const previousMessagesLength = usePrevious(messages.length);
  useEffect(() => {
    if (!previousMessagesLength && messages.length) {
      scrollMessagesList("auto", 1);
    }
  }, [messages, previousMessagesLength]);

  // Scroll when page loaded
  const previousPage = usePrevious(page);
  useEffect(() => {
    if (page > (previousPage || 1)) {
      scrollMessagesList("smooth", 0.2);
    }
  }, [previousPage, page]);

  const onMessageSend = async (
    sendPromise: Promise<ChatMessage>,
    pendingMsgText: string
  ) => {
    const sentAt = new Date().getTime();
    const pendingMsg: ChatPendingMessage = {
      message: pendingMsgText,
      status: "pending",
      sentAt: sentAt,
    };
    pushPendingMessage(pendingMsg);
    sendPromise
      .then((message) => {
        onMessageDelivered(pendingMsg, message);
        setTimeout(() => scrollMessagesList("smooth"), 10);
      })
      .catch(() => {
        onMessageFailed(pendingMsg);
      });
  };

  useListenForMessages({
    room: contact?.room ?? null,
    onMessage: (incomingMessage) => {
      pushMessage(incomingMessage);
      scrollMessagesList("auto");
    },
  });

  useListenMessageSeenByParticipant({
    room: contact?.room ?? null,
    onMessageSeen: ({ messageIds, userId }) => {
      setMessages((messages) => {
        return messages.map((msg) => {
          if (!messageIds.includes((msg as ChatMessage).id)) return msg;
          return {
            ...msg,
            seenBy: [...(msg as ChatMessage).seenBy, userId],
          };
        });
      });
    },
  });

  if (!contact) return null;
  if (!messages) return <>Loading</>;
  return (
    <React.Fragment>
      <ChatMessagesList
        ref={messagesListRef}
        messages={messages}
        onPreviousPage={onPreviousPage}
      />
      <ChatInput onSend={onMessageSend} />
    </React.Fragment>
  );
});

const ChatMessagesList = React.forwardRef<
  HTMLDivElement,
  {
    messages: (ChatMessage | ChatPendingMessage)[];
    onPreviousPage: () => void;
  }
>(function ChatMessagesList({ messages, onPreviousPage }, ref) {
  const chatState = useChatState();
  const [contact, userId] = [
    chatState.currentContact.get()!,
    chatState.userId.get(),
  ];
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { scrollTriggerRef } = useMessagesInfiniteScroll({
    containerRef,
    onPreviousPage,
  });
  const {
    handleMessageRef,
    messagesContainerRef,
  } = useDetectAndMarkSeenMessages({
    contact,
    userId,
    onMarkedAsSeen: (ids) => {
      const { room } = contact;
      chatState.unseenCountByRoom
        .nested(room)
        .set((count) => Math.max(0, (count || 0) - ids.length));
    },
  });

  if (!messages) return <>Loading</>;
  return (
    <div
      className="messages"
      id="chat"
      ref={(element) => {
        if (ref instanceof Function) ref(element);
        else if (ref) ref.current = element;
        containerRef.current = element;
        messagesContainerRef.current = element;
      }}
    >
      <div className="scroll-trigger" ref={scrollTriggerRef} />
      {messages.map((message) => {
        if (isPendingMessage(message)) {
          return (
            <PendingMessage
              key={message.message + message.sentAt}
              message={message as ChatPendingMessage}
            />
          );
        }
        return (
          <Message
            key={message.id}
            ref={handleMessageRef(message)}
            currentUserId={userId}
            message={message}
            contact={contact}
          />
        );
      })}
      {messages.length === 0 && "No messages"}
    </div>
  );
});

const Message = React.forwardRef<
  HTMLDivElement,
  {
    contact: ChatContact;
    message: ChatMessage;
    currentUserId: number;
  }
>(function Message(props, ref) {
  const { message, currentUserId, contact } = props;
  const participantId = contact.user.id;
  const type = message.authorId === currentUserId ? "outgoing" : "incoming";
  // const seenByParticipant =
  //   message.authorId !== participantId &&
  //   message.seenBy.includes(contact.user.id);

  return (
    <div ref={ref} className={`message ${type}`}>
      <span className="message__status"></span>
      <div className="message__text">{message.message}</div>
    </div>
  );
});

const PendingMessage: React.FC<{
  message: ChatPendingMessage;
}> = function PendingMessage(props) {
  const { message } = props;
  return (
    <div className={`message outgoing`}>
      <div className="message__status">
        {message.status === "error" && (
          <i
            className="fas fa-exclamation text-error"
            title="Error during sending"
          />
        )}
      </div>
      <div className="message__text">{message.message}</div>
    </div>
  );
};

const ChatInput = React.memo<{
  onSend: (promise: Promise<ChatMessage>, message: string) => void;
}>(function ChatInput({ onSend }) {
  const chatState = useChatState();
  const contact = chatState.currentContact.get();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isPending, setIsPending] = useState(false);

  const handleSend = () => {
    const message = inputRef.current?.value;
    if (!message || !contact) return;

    setIsPending(true);
    const promise: Promise<ChatMessage> = ChatService.sendMessage(
      contact.room,
      message
    ).finally(() => {
      if (inputRef.current) inputRef.current.value = "";
      setIsPending(false);
    });
    onSend(promise, message);
  };
  return (
    <div className="input">
      <input
        placeholder="Type your message here!"
        type="text"
        ref={inputRef}
        onKeyPress={(e) => {
          if (e.code.toLowerCase() === "enter") handleSend();
        }}
        maxLength={500}
      />
      <button className="send-btn" onClick={handleSend} disabled={isPending}>
        Send
      </button>
    </div>
  );
});
