import {produce} from "immer";
import _ from "lodash";
import {RecipeForm, RecipeRead} from "../../other/interfaces";
import {RecipeAction} from "./actions";

interface RecipeState {
    draft: RecipeForm | undefined;
    recipes: RecipeRead[] | undefined;
    savedRecipeIds: number[];
}

const initialState: RecipeState = {
    draft: undefined,
    recipes: undefined,
    savedRecipeIds: [],
};

export const recipeReducer = (state = initialState, action: RecipeAction) =>
    produce(state, (nextState) => {
        state = {...initialState, ...state};
        switch (action.type) {
            case "DELETE_RECIPE":
                const recipeIdx = _.findIndex(nextState.recipes, {
                    id: action.payload,
                });
                if (recipeIdx < 0) throw new Error("Recipe not found in local db");
                _.pullAt(nextState.recipes ?? [], recipeIdx); // NOTE: array mutation
                break;
            case "SAVE_RECIPE_DRAFT":
                nextState.draft = action.payload;
                break;
            case "TOGGLE_SAVE_RECIPE":
                const {id, save} = action.payload;
                if (save) nextState.savedRecipeIds.push(id);
                else _.pull(nextState.savedRecipeIds, id);
                break;
            default:
                return state;
        }
    });
