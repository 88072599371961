import { LangCode } from "../other/interfaces";

export class AccessToken {
  static set(token: string) {
    localStorage.setItem("ACCESS_TOKEN", token);
  }

  static unset() {
    localStorage.removeItem("ACCESS_TOKEN");
  }

  static get() {
    return localStorage.getItem("ACCESS_TOKEN");
  }

  static validate(): boolean {
    const token = AccessToken.get();
    return !!token;
  }
}

export class Lang {
  static readonly fallback: LangCode = "ge";

  static set(lang: string) {
    localStorage.setItem("APP_LANGUAGE", lang);
  }

  static unset() {
    localStorage.removeItem("APP_LANGUAGE");
  }

  static get(): string {
    return localStorage.getItem("APP_LANGUAGE") || Lang.fallback;
  }
}

export class LocalStorage {
  static saveAsJSON(key: string, value: any) {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  static readAsJSON<T extends any>(key: string): T | null {
    const rawValue = localStorage.getItem(key);
    if (!rawValue) return null;
    return JSON.parse(rawValue);
  }
}
