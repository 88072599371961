import React, { CSSProperties, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { RouteProps } from "react-router";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { UserBase } from "../other/interfaces";
import PuffLoader from "react-spinners/PuffLoader";
import { Button, Modal } from "react-bootstrap";
import { FeatherIcon } from "../assets/icons";
import classNames from "classnames";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import { useTranslation } from "react-i18next";
import { getThrottledCallback } from "../utils/other";
import { BTN_LOADER_MIN_DELAY, PAGE_LOADER_MIN_DELAY } from "../config";
import { useToggle } from "react-use";
import { usePostLoginRedirect, useUnmountedValue } from "../other/hooks";
import { handleLogout } from "../utils/auth";

interface UserRouteProps extends RouteProps {
  allowUnverified?: boolean;
  component: NonNullable<RouteProps["component"]>;
}

export const UserRoute = React.memo<UserRouteProps>((props) => {
  const user = useSelector<RootState, UserBase | undefined>(
    (state) => state.user.user
  );

  return user ? (
    <Route
      {...props}
      component={undefined}
      render={(componentProps) => (
        <VerifiedUserGuard allowUnverified={props.allowUnverified ?? false}>
          <props.component {...componentProps} />
        </VerifiedUserGuard>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
});

const VerifiedUserGuard: React.FC<{ allowUnverified: boolean }> = React.memo(
  ({ children, allowUnverified }) => {
    const { mobileVerified, profileCompleted } = useSelector(
      (state: RootState) => ({
        mobileVerified: !!state.user.user?.verify_mobile,
        profileCompleted: state.user.user?.complited === "complited",
      })
    );
    const isVerificationPage = !!useRouteMatch("/user/verify-mobile");
    const isProfileCompletePage = !!useRouteMatch("/user/complete-profile");

    if (!allowUnverified) {
      // First check if profile is completed
      if (!profileCompleted && !isProfileCompletePage)
        return <Redirect to="/user/complete-profile" />;
      // Then check mobile for verification
      if (!mobileVerified && !isVerificationPage)
        return <Redirect to="/user/verify-mobile" />;
    }
    return <>{children}</>;
  }
);

export const GuestOnlyRoute = React.memo<
  RouteProps & {
    forceLogout?: boolean;
  }
>((props) => {
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => !!state.user.user
  );
  if (props.forceLogout && isLoggedIn) {
    handleLogout();
    return null;
  }
  return isLoggedIn ? <Redirect to="/user/activity" /> : <Route {...props} />;
});

export const PagePreloader = React.memo<{ forceDelay?: boolean }>(
  ({ forceDelay = true }) => {
    const MIN_MS = forceDelay ? PAGE_LOADER_MIN_DELAY : 0;

    const parentDiv = useMemo(() => {
      const div = document.createElement("div");
      div.className =
        "page-overlay d-flex align-items-center justify-content-center";
      div.style.position = "fixed";
      div.style.width = "100vw";
      div.style.height = "100vh";
      div.style.zIndex = "1001";
      div.style.left = "0";
      div.style.top = "0";
      div.style.backgroundColor = "#FFF";
      return div;
    }, []);

    useEffect(() => {
      document.body.appendChild(parentDiv);
      ReactDOM.render(<PuffLoader color="#FEB601" />, parentDiv);

      const hideFn = getThrottledCallback(() => {
        ReactDOM.unmountComponentAtNode(parentDiv);
        parentDiv.remove();
      }, MIN_MS);
      return hideFn;
    }, [MIN_MS, parentDiv]);

    return null;
  }
);

interface AlertModalProps {
  variant: "error" | "warning" | "success";
  buttons?: (onClose: () => void) => React.ReactNode;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onClose: () => void;
  show: boolean;
}

export const AlertModal = React.memo<AlertModalProps>(
  ({ variant, onClose, show, buttons, title, subtitle }) => {
    const [t] = useTranslation();
    return (
      <Modal
        show={show}
        centered
        className={classNames("alert-modal", "alert-modal--" + variant)}
        size="sm"
        onHide={() => {}}
      >
        <Modal.Body className="alert-modal__body">
          <div className="icon-box">
            {variant === "error" && (
              <FeatherIcon.XCircle width={120} strokeWidth={1} height="auto" />
            )}
            {variant === "success" && (
              <FeatherIcon.Check width={120} strokeWidth={1} height="auto" />
            )}
            {variant === "warning" && (
              <FeatherIcon.AlertCircle
                width={120}
                strokeWidth={1}
                height="auto"
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="content">
            <p className="title">{title}</p>
            <p className="subtitle">{subtitle}</p>
          </div>
          <div className="buttons">
            {buttons ? (
              buttons(onClose)
            ) : (
              <Button
                variant="warning"
                className="w-auto"
                size="sm"
                onClick={() => onClose()}
              >
                {t("general.close")}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
);

export const Pagination = React.memo<
  Omit<ReactPaginateProps, "onPageChange"> & {
    iconsOnly?: boolean;
    onPageChange: (p: number) => void;
    showAlways?: boolean;
    dotted?: boolean;
  }
>(({ iconsOnly, dotted, showAlways, onPageChange, ...props }) => {
  const [t] = useTranslation();
  if (props.pageCount <= 1 && !showAlways) return null;
  const dotsLabelBuilder = () => "●";
  return (
    <ReactPaginate
      {...props}
      containerClassName={classNames(
        "pagination",
        props.containerClassName,
        dotted && "pagination__dotted"
      )}
      pageClassName="pagination__link"
      nextClassName="pagination__next next"
      previousClassName="pagination__prev previous"
      breakClassName="pagination__link break"
      activeClassName="active"
      pageLabelBuilder={dotted ? dotsLabelBuilder : undefined}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      forcePage={props.forcePage ? props.forcePage - 1 : undefined}
      initialPage={props.forcePage ? props.forcePage - 1 : undefined}
      nextLabel={
        <>
          {!iconsOnly && t("pages.pagination_next")}
          <FeatherIcon.ChevronRight className="icon" />
        </>
      }
      previousLabel={
        <>
          <FeatherIcon.ChevronLeft className="icon" />
          {!iconsOnly && t("pages.pagination_prev")}
        </>
      }
    />
  );
});

export const LoaderBox: React.FC<{
  isLoading: boolean;
  height?: number | string;
}> = ({ children, isLoading, height }) => {
  const [showLoader, setShowLoader] = useToggle(!!isLoading);
  const hideLoaderFn = useRef<Function>();
  const isUnmounted = useUnmountedValue();

  // Show loader
  useEffect(() => {
    if (!isLoading) return;
    setShowLoader(true);
    // Save delayed callback
    hideLoaderFn.current = getThrottledCallback(() => {
      setShowLoader(false);
    }, BTN_LOADER_MIN_DELAY);
  }, [isLoading, isUnmounted, setShowLoader]);

  // Remove the loader
  useEffect(() => {
    if (!isLoading) hideLoaderFn.current?.();
  }, [isLoading]);

  const style: CSSProperties = { height };
  return (
    <div
      className={classNames(
        "loader-box",
        showLoader && "is-loading",
        height && "fixed-height"
      )}
    >
      <div className="loader-wrapper">
        <div className="loader" style={style}>
          <PuffLoader color="#FEB601" />
        </div>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};
