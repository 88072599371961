import useFetch, { UseFetch } from "use-http";
import {
  IncomingOptions,
  OverwriteGlobalOptions,
} from "use-http/dist/cjs/types";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as querystring from "querystring";
import { useLatest, usePrevious, useUnmount } from "react-use";
import {
  ApiCustomError,
  ApiFieldsError,
  NotFoundError,
  UnknownNetworkError,
} from "../utils/other";
import {
  ApiCustomErrorResponse,
  ApiFieldsErrorResponse,
  LangCode,
} from "./interfaces";
import { useLang } from "./hooks";
import { Lang } from "../utils/storage";

type BodyInit =
  | Blob
  | ArrayBufferView
  | ArrayBuffer
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | string;
type AnyRecord = Record<any, any>;
type UseFetchOriginal = Omit<UseFetch<any>, "get" | "post">;

// Request type / Response type
interface DataTypes {
  post?: [unknown, unknown];
  put?: [unknown, unknown];
  delete?: [unknown, unknown];
  get?: [unknown, unknown];
  patch?: [unknown, unknown];
}

declare type HttpMethodFn<
  Data extends [unknown, unknown] | undefined
> = Data extends [unknown, unknown]
  ? (body: Data[0]) => Promise<Data[1]>
  : (body?: BodyInit | object) => Promise<unknown>;

declare type GetFn<Data extends [unknown, unknown] | undefined> = (
  params: Data extends Array<any> ? Data[0] : AnyRecord
) => Promise<Data extends Array<any> ? Data[1] : unknown>;

type CustomMethods<T extends DataTypes> = {
  get: GetFn<T["get"]>;
  post: HttpMethodFn<T["post"]>;
  patch: HttpMethodFn<T["put"]>;
  put: HttpMethodFn<T["put"]>;
  del: HttpMethodFn<T["delete"]>;
  delete: HttpMethodFn<T["delete"]>;
};
export type UseHttp<T extends DataTypes> = Omit<
  UseFetchOriginal,
  keyof CustomMethods<any>
> &
  CustomMethods<T>;

type UseHttpConfig<T extends DataTypes> = (
  | IncomingOptions
  | OverwriteGlobalOptions
) & {
  onSuccess?: (r: T extends [unknown, unknown] ? T[1] : undefined) => void;
};
export const useHttp = <T extends DataTypes>(
  requestURL: string,
  config?: UseHttpConfig<T>
): UseHttp<T> => {
  const error = useRef<Error>();
  const useFetchOriginal = useFetch("", config);
  const isUnmountedRef = useRef(false);
  const onSuccess = useLatest(<T extends any>(response: T): T | void => {
    // Fixme experimental change(isUnmounted check removed)
    // if (!isUnmountedRef.current) {
    if (useFetchOriginal.response.ok) {
      config?.onSuccess?.(response as any);
      return response;
    }
    if ((response as ApiFieldsErrorResponse | undefined)?.errors)
      error.current = new ApiFieldsError(response as any);
    else if ((response as ApiCustomErrorResponse | undefined)?.error)
      error.current = new ApiCustomError(response as any);
    throw error.current || response;
    // }
  });
  useUnmount(() => {
    isUnmountedRef.current = true;
  });
  const onError = useLatest(<T extends any>(response: T): T | void => {
    if (!isUnmountedRef.current) {
      if (!error.current) error.current = useFetchOriginal.error;
      if (useFetchOriginal.response.status === 404) {
        error.current = new NotFoundError(response as string);
      }

      throw error.current || response;
    }
  });

  const get: GetFn<T["get"]> = useCallback(
    (params: any) =>
      useFetchOriginal
        .get(
          replaceVariablesInURL(requestURL, params) +
            "?" +
            querystring.stringify(params)
        )
        .then(onSuccess.current)
        .catch(onError.current),
    [onError, onSuccess, requestURL, useFetchOriginal]
  );
  const post: HttpMethodFn<T["post"]> = useCallback(
    (params: any) =>
      useFetchOriginal
        .post(replaceVariablesInURL(requestURL, params), params)
        .then(onSuccess.current)
        .catch(onError.current),
    [onError, onSuccess, requestURL, useFetchOriginal]
  ) as any;
  const put: HttpMethodFn<T["put"]> = useCallback(
    (params: any) =>
      useFetchOriginal
        .put(replaceVariablesInURL(requestURL, params), params)
        .then(onSuccess.current)
        .catch(onError.current),
    [onError, onSuccess, requestURL, useFetchOriginal]
  ) as any;
  const patch: HttpMethodFn<T["patch"]> = useCallback(
    (params: any) =>
      useFetchOriginal
        .patch(replaceVariablesInURL(requestURL, params), params)
        .then(onSuccess.current)
        .catch(onError.current),
    [onError, onSuccess, requestURL, useFetchOriginal]
  ) as any;
  const del: HttpMethodFn<T["delete"]> = useCallback(
    (params: any) =>
      useFetchOriginal
        .delete(replaceVariablesInURL(requestURL, params), params)
        .then(onSuccess.current)
        .catch(onError.current),
    [onError, onSuccess, requestURL, useFetchOriginal]
  ) as any;

  return useMemo(
    () =>
      ({
        ...useFetchOriginal,
        error: error.current,
        get,
        post,
        put,
        del,
        delete: del,
        patch,
      } as any),
    [del, get, patch, post, put, useFetchOriginal, error]
  );
};

const replaceVariablesInURL = (
  url: string,
  values: Record<string, unknown>
) => {
  if (typeof values !== "object" || values === null) return url;
  let result = url;
  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (typeof value !== "number" && typeof value !== "string") return;
    result = result.replace(
      new RegExp(`(:${key})|({${key}})`, "g"),
      value.toString()
    );
  });

  return result;
};

export const useGet = <
  Func extends UseHttp<any>,
  Prop extends string = "response",
  Res = ReturnType<Func["get"]> extends Promise<infer R> ? R | undefined : never
>(
  http: Func,
  arg: Parameters<Func["get"]>[0] | null,
  prop?: Prop,
  config?: { refreshOnLanguageChange?: boolean }
): Record<Prop, Res> & {
  isLoading: boolean;
  setResponse: Dispatch<SetStateAction<Res | undefined>>;
  refresh: () => void;
  http: Func;
} => {
  const [response, setResponse] = useState<ReturnType<Func["get"]>>();
  const [httpRef, configRef] = [useLatest(http), useLatest(config)];
  const [networkErr, setNetworkErr] = useState<Error>();
  const lang = useLang() as LangCode;
  const isInitialized = useRef(false);

  const handleRefresh = useCallback(
    (lang?: LangCode) => {
      if (arg === null) return;
      httpRef.current
        .get({ ...arg, lang })
        .then(setResponse)
        .catch((err) => {
          if (!err) setNetworkErr(new UnknownNetworkError());
          else setNetworkErr(err);
        });
    },
    [arg, httpRef]
  );

  useEffect(
    () => handleRefresh(lang),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRefresh]
  );
  useEffect(() => {
    if (isInitialized.current) {
      const config = configRef.current;
      if (config?.refreshOnLanguageChange) {
        handleRefresh(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configRef, lang]);

  useEffect(() => {
    isInitialized.current = true;
  }, []);

  if (http.error) throw http.error;
  if (networkErr) throw networkErr;

  return useMemo(
    () => ({
      [prop ?? "response"]: response,
      isLoading: http.loading,
      setResponse,
      refresh: handleRefresh,
      http: httpRef.current,
    }),
    [prop, httpRef, response, handleRefresh, http.loading]
  ) as any;
};
