// Hook
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useEffectOnce,
  useInterval,
  useLatest,
  useList,
  useNumber,
} from "react-use";
import { useHistory } from "react-router";
import { RootState, store } from "../store";
import { generalApiHooks } from "../api/general";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { DinnerRead, LangCode } from "./interfaces";
import addMinutes from "date-fns/addMinutes";
import { Locale, parseISO } from "date-fns";
import i18n from "i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi, { AsyncValidationOptions } from "joi";
import { joiPreferences } from "../i18n";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/other";
import { enUS, ka, ru } from "date-fns/locale";

export const useCountries = () => {
  const { countries } = generalApiHooks.useCountries();

  return useMemo(() => ({ countries }), [countries]);
};

export const useForceRender = () => useNumber(1)[1].inc;

export const getObligatoryActionRoute = () => {
  const {
    user,
    allergiesSetupSkipped,
    interestsSetupSkipped,
  } = store.getState().user;
  if (!user) return "/login";

  console.debug("user", user);
  if (user.complited !== "complited") return "/user/complete-profile";
  if (user.verify_mobile === 0 && user.mobile) return "/user/verify-mobile";
  if (user.verify_email === 0 && user.email) return "/user/verify-email";
  if (!user.welcome_seen) {
    if (user.is_cook === -1) return "/user/choose-profile";
    if (!user.CategoryChecked && !interestsSetupSkipped)
      return "/gourmet/setup/interests";
    if (!user.AllergiesChecked && !allergiesSetupSkipped)
      return "/gourmet/setup/allergies";

    return "/user/welcome";
  }
};
export const usePostLoginRedirect = () => {
  const history = useHistory();

  const getNextRoute = useCallback(() => {
    try {
      const obligatoryAction = getObligatoryActionRoute();
      const user = store.getState().user.user;

      if (!user) return "/login";
      return obligatoryAction || "/user/activity";
    } catch (e) {
      console.error("getNextRoute Error: ", e);
      throw e;
    }
  }, []);

  return useCallback(() => {
    const nextRoute = getNextRoute();
    console.debug("nextRoute", nextRoute);
    history.push(nextRoute);
  }, [history, getNextRoute]);
};

export const useMiniCallback = <
  A extends any[],
  R extends any,
  Fn extends (...args: A) => R
>(
  fn: Fn
): Fn => {
  const fnRef = useLatest(fn);
  return useCallback((...args: A) => fnRef.current(...args), [fnRef]) as Fn;
};

export const usePagination = () => {
  const [state, setState] = useState({ currentPage: 1, pageCount: 1 });

  const onPageCountChange = useMiniCallback(
    (count: number | undefined | null) => {
      if (!count || count === state.pageCount) return;
      setState((state) => ({
        pageCount: count,
        currentPage: Math.min(state.currentPage, count),
      }));
    }
  );
  const onPageChange = useMiniCallback((page: number) => {
    if (state.pageCount < page) return;
    setState((state) => ({
      ...state,
      currentPage: page,
    }));
  });

  return useMemo(() => ({ onPageCountChange, onPageChange, ...state }), [
    onPageCountChange,
    onPageChange,
    state,
  ]);
};

export const useLocalCRUD = <T extends unknown>(
  docs: T[] | undefined
): {
  deleteAt: (idx: number) => void;
  updateAt: (idx: number, item: T) => void;
  docs: T[] | undefined;
} => {
  const [currentDocs, currentDocsFns] = useList(docs);

  useEffect(() => currentDocsFns.set(docs as any), [currentDocsFns, docs]);
  return useMemo(
    () => ({
      docs: currentDocs,
      deleteAt: currentDocsFns.removeAt,
      updateAt: currentDocsFns.updateAt,
    }),
    [currentDocs, currentDocsFns]
  ) as any;
};

export const useUnmountedValue = () => {
  const isUnmountedRef = useRef(false);
  useEffect(
    () => () => {
      isUnmountedRef.current = true;
    },
    []
  );
  return isUnmountedRef;
};

export const useDinnerProgress = (dinner: DinnerRead | undefined) => {
  const [state, setState] = useState({
    progress: 0,
    timeLeft: 0,
  });
  const stateRef = useLatest(state);
  const delay = state.progress < 100 ? 10000 : null;

  const recalculate = useCallback(() => {
    if (!dinner) return;
    const cookStartedAt = parseISO(dinner.created_at);
    const willCookedAt = addMinutes(cookStartedAt, dinner.cook_time);
    const now = new Date();

    if (now >= willCookedAt) {
      return setState({
        progress: 100,
        timeLeft: 0,
      });
    }
    if (stateRef.current.progress === 100) return;

    // Calculate
    const timePassed = differenceInMinutes(new Date(), cookStartedAt);
    const timeLeft = Math.max(dinner.cook_time - timePassed, 0);

    const progress = Math.round(
      ((dinner.cook_time - timePassed) / dinner.cook_time) * 100
    );
    setState({
      progress,
      timeLeft,
    });
  }, [dinner, stateRef]);

  useInterval(recalculate, delay);
  useEffect(recalculate, [recalculate]);

  return useMemo(
    () => ({ progress: state.progress, minutesLeft: state.timeLeft }),
    [state]
  );
};

export const useJoiResolver = (
  schema: Joi.AnySchema,
  options?: AsyncValidationOptions
) => {
  const [lang, setLang] = useState(i18n.language);

  useEffectOnce(() => {
    i18n.on("languageChanged", setLang);
  });

  return useMemo(() => {
    return joiResolver(schema, {
      ...joiPreferences,
      ...options,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, options, schema]);
};

export const useLang = () => {
  const [lang, setLang] = useState(i18n.language as LangCode);

  useEffectOnce(() => {
    i18n.on("languageChanged", setLang);
  });

  return lang;
};

export const useUser = () => useSelector((state: RootState) => state.user.user);

export const useFormatDate = () => {
  const lang = useLang() as LangCode;
  const locale = useMemo<Locale>(() => {
    const mappings: Record<LangCode, Locale> = {
      en: enUS,
      ge: ka,
      ru,
    };
    return mappings[lang];
  }, [lang]);

  return useCallback(
    (arg: Date | string, format?: string) => formatDate(arg, locale, format),
    [locale]
  );
};
