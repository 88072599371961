import * as FeatherIcon from "react-feather";
import React, { SVGProps } from "react";
import { ReactComponent as PlusIconSVG } from "./plus.svg";
import { ReactComponent as MinusIconSVG } from "./minus.svg";
import { ReactComponent as BowlIconSVG } from "./bowl.svg";
import { ReactComponent as ChatIconSVG } from "./chat.svg";
import { ReactComponent as CheckIconSVG } from "./check.svg";
import { ReactComponent as InfoIconSVG } from "./info.svg";
import { ReactComponent as SearchIconSVG } from "./search.svg";
import { ReactComponent as ClockIconSVG } from "./clock.svg";
import { ReactComponent as HeartIconSVG } from "./heart.svg";
import { ReactComponent as CloseIconSVG } from "./close.svg";
import { ReactComponent as PlayIconSVG } from "./play.svg";
import { ReactComponent as RocketIconSVG } from "./rocket.svg";

type IconName =
  | "Plus"
  | "Minus"
  | "Bowl"
  | "Close"
  | "Chat"
  | "Check"
  | "Info"
  | "Heart"
  | "Clock"
  | "Search"
  | "Rocket"
  | "Play";

export const CustomIcon: Record<IconName, React.FC<SVGProps<any>>> = {
  Plus: (props) => <PlusIconSVG {...props} />,
  Bowl: (props) => <BowlIconSVG {...props} />,
  Chat: (props) => <ChatIconSVG {...props} />,
  Check: (props) => <CheckIconSVG {...props} />,
  Info: (props) => <InfoIconSVG {...props} />,
  Search: (props) => <SearchIconSVG {...props} />,
  Minus: (props) => <MinusIconSVG {...props} />,
  Clock: (props) => <ClockIconSVG {...props} />,
  Heart: (props) => <HeartIconSVG {...props} />,
  Close: (props) => <CloseIconSVG {...props} />,
  Play: (props) => <PlayIconSVG {...props} />,
  Rocket: (props) => <RocketIconSVG {...props} />,
};

export { FeatherIcon };
