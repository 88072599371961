import Joi from "joi";

export interface DeliveryMethodBase {
  id: number;
  name: string;
  address_required: BooleanLike;
  price_required: BooleanLike;
  distance_required: BooleanLike;
  price: number;
}

export interface DeliveryMethodRelation {
  id: number;
  delivery_id: number;
  name: string;
}

export enum DinnerType {
  // eslint-disable-next-line no-unused-vars
  Hot = "hot",
  // eslint-disable-next-line no-unused-vars
  Cold = "cold",
}

const dinnerTypes = [DinnerType.Hot, DinnerType.Cold];

export interface DinnerInformationForm {
  name: string;
  categories: number[];
  ingredients: number[];
  cook_time: number;
  type: DinnerType;
}

export const dinnerInformationFormSchema = Joi.object<DinnerInformationForm>({
  name: Joi.string().min(2).required(),
  categories: Joi.array().items(Joi.number()).required(),
  ingredients: Joi.array().items(Joi.number()).required(),
  cook_time: Joi.number().min(1).required(),
  type: Joi.string()
    .valid(...dinnerTypes)
    .required(),
}).required();

export interface DinnerDeliveryAndPortionForm {
  unit_type: "portion" | "piece";
  quantity: number;
  weight: number;
  unit_price: number;
  delivery_method: number[];
  delivery_price: number | undefined;
  pictures: string[];
  video: string | null;
}

export const dinnerDeliveryAndPortionFormSchema = Joi.object<DinnerDeliveryAndPortionForm>(
  {
    unit_type: Joi.string().valid("portion", "piece").required(),
    quantity: Joi.number().min(1).required(),
    unit_price: Joi.number().min(0.01).required(),
    delivery_method: Joi.array().items(Joi.number().valid()).min(1).required(),
    delivery_price: Joi.number().min(0.01).allow(null),
    pictures: Joi.any(),
    video: Joi.any(),
    weight: Joi.alternatives().conditional("unit_type", {
      is: "portion",
      then: Joi.number().min(1).required(),
      otherwise: Joi.any(),
    }),
  }
).required();

export type DinnerForm = DinnerInformationForm & DinnerDeliveryAndPortionForm;
