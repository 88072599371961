import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChatService } from "./chat.service";
import {
  useEffectOnce,
  useInterval,
  useLatest,
  useList,
  useUnmount,
} from "react-use";
import {
  ChatContact,
  ChatMessage,
  ChatPendingMessage,
} from "./chat.interfaces";
import { ListenEvents } from "./socket.interfaces";
import { debounceCallback, deepEqual } from "./chat.helpers";

export const useInitChatService = () => {
  const [serviceInitialized, setServiceInitialized] = useState(false);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    setLoading(true);
    ChatService.initialize()
      .then(() => setServiceInitialized(true))
      .catch(setError)
      .finally(() => setLoading(false));
  });

  return useMemo(() => {
    return {
      error,
      loading,
      isInitialized: serviceInitialized,
      chatService: serviceInitialized ? ChatService : null,
    };
  }, [error, loading, serviceInitialized]);
};

export const useChatContacts = () => {
  const [contacts, contactsFns] = useList<ChatContact>();
  const { chatService } = useInitChatService();

  // Filter expired contacts
  useInterval(() => {
    const isExpired = ({ expiryDate }: ChatContact) => {
      return expiryDate && new Date(expiryDate) <= new Date();
    };
    const expiredContacts = contacts.filter(isExpired);
    if (expiredContacts.length) {
      contactsFns.set((contacts) => contacts.filter((c) => !isExpired(c)));
    }
  }, 1 * 1000);

  // Fetch & listen new contacts
  useEffect(() => {
    if (!chatService) return;
    chatService.getContacts().then(contactsFns.set);

    const newContactListener = (contact: ChatContact | ChatContact[]) => {
      if (Array.isArray(contact)) contactsFns.push(...contact);
      else contactsFns.push(contact);
    };
    chatService.onNewContact(newContactListener);

    return () => {
      chatService.removeSocketListener(undefined, newContactListener);
    };
  }, [chatService, contactsFns]);

  return useMemo(() => {
    const contactUserIds = contacts.map((c) => c.user.id);
    return {
      contacts,
      contactsFns,
      contactUserIds,
    };
  }, [contacts, contactsFns]);
};

export function useToggleChatBox(
  chatElement: React.RefObject<HTMLDivElement | null>
) {
  return useCallback(
    (open: boolean) => {
      const element = chatElement!.current;
      if (!element) throw new Error("Chat element not found");

      const isClosed = element.classList.contains("closed");
      // Close
      if (!open && !isClosed) {
        element.classList.add("closing");
        setTimeout(() => {
          element?.classList.add("closed");
          element?.classList.remove("closing");
        }, 600);
      }
      // Open
      else if (isClosed) {
        element?.classList.remove("closed");
      }
    },
    [chatElement]
  );
}

export const useListenForMessages = (arg: {
  onMessage: (msg: ChatMessage) => void;
  room?: number | null;
}) => {
  const { chatService } = useInitChatService();
  const argRef = useLatest(arg);

  const messageListener = useCallback(
    (message: ChatMessage) => {
      const arg = argRef.current;
      if (arg.room === undefined || message.room === arg.room) {
        argRef.current.onMessage(message);
      }
    },
    [argRef]
  );

  useEffect(() => {
    if (!chatService || arg.room === null) return;
    chatService.onMessage(undefined, messageListener);
    return () => {
      chatService.removeSocketListener(undefined, messageListener);
    };
  }, [arg.room, chatService, messageListener]);
};

export const useMessagesList = (room: number | undefined) => {
  const [messages, messagesFns] = useList<ChatMessage | ChatPendingMessage>();
  const messagesRef = useLatest(messages);

  const deliveredPendingMessages = useRef<
    { pendingMessage: ChatPendingMessage; message: ChatMessage }[]
  >([]);
  const failedPendingMessages = useRef<ChatPendingMessage[]>([]);

  const [page, setPage] = useState(1);
  const endReached = useRef(false);

  const onPreviousPage = useCallback(() => {
    if (!endReached.current) setPage((p) => p + 1);
  }, []);

  const pushPendingMessage = useCallback(
    (msg: ChatPendingMessage) => {
      messagesFns.push(msg);
    },
    [messagesFns]
  );

  // Convert delivered messages from pending to delivered
  const updatePendingMessages = useCallback(() => {
    const deliveredMessages = deliveredPendingMessages.current;
    deliveredPendingMessages.current = [];
    deliveredMessages.forEach(({ pendingMessage, message }) => {
      const idx = messagesRef.current.findIndex((msg) =>
        deepEqual(msg, pendingMessage)
      );
      if (idx === -1) throw new Error("delivered message not found in state");
      messagesFns.updateAt(idx, message);
    });
  }, [messagesFns, messagesRef]);

  // Mark failed messages
  const updateFailedMessages = useCallback(() => {
    const failedMessages = failedPendingMessages.current;
    failedPendingMessages.current = [];
    failedMessages.forEach((failedMessage) => {
      const idx = messagesRef.current.findIndex((msg) =>
        deepEqual(msg, failedMessage)
      );
      if (idx === -1) throw new Error("failed message not found in state");
      messagesFns.updateAt(idx, failedMessage);
    });
  }, [messagesRef, messagesFns]);

  const onMessageDelivered = useCallback(
    (pendingMessage: ChatPendingMessage, message: ChatMessage) => {
      deliveredPendingMessages.current.push({ message, pendingMessage });
      updatePendingMessages();
    },
    [updatePendingMessages]
  );

  const onMessageFailed = useCallback(
    (pendingMessage: ChatPendingMessage) => {
      failedPendingMessages.current.push(pendingMessage);
      updateFailedMessages();
    },
    [updateFailedMessages]
  );

  // Clear state on room change
  useEffect(() => {
    messagesFns.clear();
    endReached.current = false;
    setPage(1);
  }, [room, messagesFns]);

  // Load next page and push to list
  useEffect(() => {
    if (!room) return;

    ChatService.getMessages(room, page).then((messages) => {
      if (messages.length === 0) {
        endReached.current = true;
        return;
      }
      messagesFns.set((current) => [...messages.reverse(), ...current]);
    });
  }, [messagesFns, page, room]);

  return useMemo(
    () => ({
      messages,
      setMessages: messagesFns.set,
      updateMessageAt: messagesFns.updateAt,
      pushMessage: messagesFns.push,
      onPreviousPage,
      page,
      pushPendingMessage,
      onMessageDelivered,
      onMessageFailed,
    }),
    [
      messages,
      messagesFns,
      onMessageDelivered,
      onPreviousPage,
      page,
      pushPendingMessage,
      onMessageFailed,
    ]
  );
};

export const useListenMessageSeenByParticipant = (arg: {
  onMessageSeen: (arg: Parameters<ListenEvents["messages-seen"]>[0]) => void;
  room: number | null | undefined;
}) => {
  const argRef = useLatest(arg);

  const listener = useCallback(
    (arg: Parameters<ListenEvents["messages-seen"]>[0]) => {
      argRef.current.onMessageSeen(arg);
    },
    [argRef]
  );

  useEffect(() => {
    if (arg.room === null) return;
    // On message seen by participant
    ChatService.onMessagesSeen(arg.room, listener);

    return () => {
      ChatService.removeSocketListener(undefined, listener);
    };
  }, [arg.room, argRef, listener]);
};

// useVisibilityObserver calls onSeen when user has seen a message
function useVisibilityObserver(arg: {
  container: React.RefObject<HTMLDivElement>;
  onSeen: (
    items: Array<{ messageId: number; entry: IntersectionObserverEntry }>
  ) => void;
}) {
  // Detect "seen" messages
  const handleIntersection: IntersectionObserverCallback = (entries) => {
    const items: Array<{
      messageId: number;
      entry: IntersectionObserverEntry;
    }> = [];
    entries.forEach((entry) => {
      const messageId = entry.target.getAttribute("data-message-id");
      if (entry.isIntersecting && messageId) {
        items.push({ messageId: +messageId, entry });
      }
    });

    if (items.length) arg.onSeen(items);
  };
  const handleIntersectionRef = useLatest(handleIntersection);

  const observer = useMemo(() => {
    return new IntersectionObserver(handleIntersectionRef.current, {
      root: arg.container.current,
      rootMargin: "0px",
      threshold: 0.5,
    });
  }, [arg.container, handleIntersectionRef]);

  useUnmount(() => observer.disconnect());

  return observer;
}

export function useDetectAndMarkSeenMessages(arg: {
  userId: number;
  contact: ChatContact;
  onMarkedAsSeen?: (messageIds: number[]) => void;
}) {
  const { userId, contact, onMarkedAsSeen } = arg;

  // Need to be assigned to the container of messages
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  // Contains messageIds which are already marked as seen.
  const seenMessageIds = useRef(new Set<number>());

  const onMessagesSeen = async (
    items: Array<{ messageId: number; entry: IntersectionObserverEntry }>
  ) => {
    const ids = items.map((i) => i.messageId);
    try {
      await ChatService.markMessagesAsSeen(ids, contact.room);
      // Unobserve elements
      ids.forEach((id) => seenMessageIds.current.add(id));
      items.forEach((i) => visibilityObserver.unobserve(i.entry.target));
      onMarkedAsSeen?.(ids);
    } catch (e) {
      console.error("ChatService.markMessagesAsSeen error: ", e);
    }
  };

  const visibilityObserver = useVisibilityObserver({
    container: messagesContainerRef,
    onSeen: onMessagesSeen,
  });

  // Attach or remove observer for each messages
  const handleMessageRef = useCallback(
    (message: ChatMessage) => (el: HTMLDivElement | null) => {
      let isAlreadySeen = message.seenBy.includes(userId);
      const isOutgoing = message.authorId === userId;

      if (seenMessageIds.current.has(message.id)) {
        isAlreadySeen = true;
      }
      if (el && isAlreadySeen) {
        visibilityObserver.unobserve(el);
        return;
      }
      if (el && !isOutgoing) {
        el.setAttribute("data-message-id", message.id.toString());
        visibilityObserver.observe(el);
      }
    },
    [userId, visibilityObserver]
  );

  return useMemo(
    () => ({ handleMessageRef, messagesContainerRef }),
    [handleMessageRef]
  );
}

export function useMessagesInfiniteScroll(arg: {
  containerRef: React.RefObject<HTMLDivElement | null>;
  onPreviousPage: () => void;
}) {
  const { containerRef } = arg;
  const argRef = useLatest(arg);
  const scrollTriggerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current || !scrollTriggerRef.current) return;
    const observer = new IntersectionObserver(
      (entities) => {
        const entity = entities[0];
        const childrenCount = containerRef.current?.childElementCount;
        if (entity.isIntersecting && childrenCount) {
          argRef.current.onPreviousPage();
        }
      },
      {
        root: containerRef.current,
        rootMargin: "0px",
        threshold: 0,
      }
    );
    observer.observe(scrollTriggerRef.current);
    return () => observer.disconnect();
  }, [argRef, containerRef]);

  return useMemo(() => ({ scrollTriggerRef }), []);
}
