import { produce } from "immer";
import _ from "lodash";
import { DinnerAction } from "./actions";
import {
  DinnerDeliveryAndPortionForm,
  DinnerInformationForm,
} from "../../pages/Dinner/dinner.validators";
import { DinnerRead } from "../../other/interfaces";

interface DinnerState {
  draft:
    | Partial<DinnerInformationForm & DinnerDeliveryAndPortionForm>
    | undefined;
  dinners: DinnerRead[] | undefined;
  favoriteIds: number[];
}

const initialState: DinnerState = {
  draft: undefined,
  dinners: undefined,
  favoriteIds: [],
};

export const dinnerReducer = (state = initialState, action: DinnerAction) =>
  produce(state, (nextState) => {
    switch (action.type) {
      case "UPDATE_DISH":
        const idx = _.findIndex(nextState.dinners, { id: action.payload.id });
        if (idx < 0) throw new Error("Dinner not found in local db");
        nextState.dinners![idx] = _.assign(
          nextState.dinners![idx],
          action.payload.fields
        );
        nextState.draft = undefined;
        break;
      case "DELETE_DISH":
        const recipeIdx = _.findIndex(nextState.dinners, {
          id: action.payload,
        });
        if (recipeIdx < 0) throw new Error("Dinner not found in local db");
        _.pullAt(nextState.dinners ?? [], recipeIdx); // NOTE: array mutation
        break;
      case "LOAD_DISHES":
        nextState.dinners = action.payload;
        break;
      default:
        return state;
    }
  });
