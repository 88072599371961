import { IncomingOptions } from "use-http/dist/cjs/types";
import { CachePolicies } from "use-http";
import { AccessToken } from "./utils/storage";
import { BrowserOptions } from "@sentry/browser/dist/backend";
import { Integrations } from "@sentry/tracing";
import { handleLogout } from "./utils/auth";
import { store } from "./store";

export const MOBILE_VERIFY_CODE_THRESHOLD = 60;
export const EMAIL_VERIFY_CODE_THRESHOLD = 60;
export const GOOGLE_MAP_API_KEY = "AIzaSyAq7IO29ZoWgOPDf6b6kbErF8wvzg74Yew";

export const ALLOWED_PHOTO_MIMES = ["image/jpeg", "image/png"];
export const ALLOWED_VIDEO_MIMES = [
  "video/mp4",
  "video/mpeg",
  "video/quicktime",
];

export const MEDIA_BASE_URLS = {
  profile: {
    image: "/uploads/profile/photos/thumb",
    video: "/uploads/profile/videos",
  },
  dinners: {
    image: "/uploads/dinner/photos/thumb",
    video: "/uploads/dinner/videos",
  },
  recipes: {
    image: "/uploads/recipes/photos/thumb",
    video: "/uploads/recipes/videos",
  },
};

export const BTN_LOADER_MIN_DELAY = 500;
export const PAGE_LOADER_MIN_DELAY = 300;

export const IS_DEV_MODE = process.env?.NODE_ENV === "development";

export const UPLOAD_IMAGE_DIMENSIONS = {
  profile: {
    min: {
      width: 150,
      height: 150,
    },
    max: {
      width: 300,
      height: 300,
    },
  },
  dinners: {
    min: {
      width: 600,
      height: 450,
    },
    max: {
      width: 1000,
      height: 750,
    },
  },
  recipes: {
    min: {
      width: 600,
      height: 450,
    },
    max: {
      width: 1000,
      height: 750,
    },
  },
};

export const fetchOptions: IncomingOptions = {
  retries: 1,
  retryDelay: 1000,
  cachePolicy: CachePolicies.NO_CACHE,
  headers: {},
  interceptors: {
    request: async (req) => {
      const token = AccessToken.get();
      const headers = req.options.headers as Record<string, string>;
      if (token) headers.Authorization = "Bearer " + token;

      // Set response code for stoplight mock server
      if (req.url?.includes("stoplight")) {
        const code = localStorage.getItem("__code");
        const example = localStorage.getItem("__example");

        headers.Prefer = code ? `code=${code}` : "";
        if (example) headers.Prefer += ",example=" + example;
      }

      return req.options;
    },
    response: async (res) => {
      if (res.response.status === 401) {
        handleLogout();
        return {
          ...res.response,
          data: undefined,
        };
      }
      return res.response;
    },
  },
};

export const sentryOptions: BrowserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  denyUrls: [/^http?s:\/\/localhost/],
  ignoreErrors: ["ResizeObserver loop limit exceeded"],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend(event) {
    (event as any).user = store.getState().user;
    return event;
  },
};
