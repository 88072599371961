import {combineReducers, createStore} from "redux";
import {throttle} from "lodash";
import {userReducer} from "./user/reducer";
import {LocalStorage} from "../utils/storage";
import {recipeReducer} from "./recipes/reducer";
import {dinnerReducer} from "./dinner/reducer";

const rootReducer = combineReducers({
    user: userReducer,
    recipe: recipeReducer,
    dinner: dinnerReducer,
});
const preloadedState =
    LocalStorage.readAsJSON<Record<any, any> | null>("REDUX_STATE") ?? undefined;

export const store = createStore(
    rootReducer,
    preloadedState,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
// Save store
store.subscribe(
    throttle(() => {
        LocalStorage.saveAsJSON("REDUX_STATE", store.getState());
    }, 300)
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
