import { useGet, useHttp } from "../other/use-http";
import {
  Follower,
  Following,
  PaginationQuery,
  PaginationResponse,
  UploadedFile,
  UserRead,
  UserType,
  UserWrite,
} from "../other/interfaces";
import { useEffect, useMemo, useState } from "react";
import { useLatest } from "react-use";
import { useDispatch } from "react-redux";
import { userActions } from "../store/user/actions";
import ChangePassword from "../pages/User/ChangePassword";

interface BecomeCookRequest {
  iban: string;
  id_number: string;
}

type ProfileUpdateRequest = Partial<UserWrite>;
type ProfileCompleteRequest = UserWrite;
type GetProfileResponse = UserRead;

interface OrderUserBody {
  id: number;
  order_id?: number | null;
  cause_id: number;
  message: string;
}

export type GetPublicUserResponse = Pick<
  UserRead,
  "name" | "lastname" | "id" | "about_me" | "profile_image" | "is_cook"
> & {
  CategoryChecked: number[];
};
export type ChooseUserTypeReq =
  | {
      user_type: UserType.Cook;
      iban: string;
      id_number: string;
    }
  | {
      user_type: UserType.Gourmet;
    };

interface GetUserRateRequest {
  page: number;
  id: number;
}
export interface GetUserRateResponse
  extends PaginationResponse<{
    id: number;
    rate: number;
    comment: string | null;
    user: Pick<UserRead, "id" | "name" | "lastname"> & {
      profile: string;
    };
    created_at: Date | string;
    Dinner: {
      dinner_id: number;
      dinner_name: string;
    };
  }> {
  total_rate: number;
}

export interface ChangePasswordRequest {
  current_password: string;
  password: string;
}

export type ProfileUpdateError = "Invalid Iban";
export type BecomeCookError = "Invalid Iban";

export const userApiHooks = {
  useReportUser: () =>
    useHttp<{
      post: [OrderUserBody, void];
    }>("/user/{id}/report"),
  useBecomeCook: () =>
    useHttp<{ put: [BecomeCookRequest, void] }>("/user/become-cook"),
  useChooseUserType: () =>
    useHttp<{ put: [ChooseUserTypeReq, void] }>("/user/user-type"),
  useUploadProfilePhoto: () =>
    useHttp<{ post: [FormData, UploadedFile] }>("/user/profile/photo"),
  useProfileComplete: () =>
    useHttp<{ put: [ProfileCompleteRequest, void] }>("/user/profile_complate"),
  useChangePassword: () =>
    useHttp<{ put: [ChangePasswordRequest, void] }>("/user/password_change"),
  useProfileUpdate: () =>
    useHttp<{ put: [ProfileUpdateRequest, void] }>("/user/profile"),
  useProfile: () => {
    const [profile, setProfile] = useState<GetProfileResponse>();

    const dispatch = useDispatch();
    const http = useHttp<{ get: [void, GetProfileResponse] }>("/user/profile", {
      suspense: true,
    });
    const httpRef = useLatest(http);

    useEffect(() => {
      httpRef.current.get(undefined).then((profile) => {
        setProfile(profile);
        dispatch(userActions.updateProfile(profile));
      });
    }, [dispatch, httpRef]);

    return useMemo(() => ({ profile, isLoading: http.loading }), [
      profile,
      http.loading,
    ]);
  },
  usePublicUserById: (id: number | undefined) => {
    const http = useHttp<{
      get: [{ id: number }, GetPublicUserResponse];
    }>("/public/users/{id}", {
      suspense: true,
    });

    const arg = useMemo(() => (id ? { id } : null), [id]);
    return useGet(http, arg, "user");
  },
  useUserIsFollowing: (id: number | undefined) => {
    const http = useHttp<{
      get: [{ id: number }, { is_follow: BooleanLike }];
    }>("/user/users/{id}/follow", {
      suspense: true,
    });

    const arg = useMemo(() => (id ? { id } : null), [id]);
    return useGet(http, arg);
  },
  useUserFollow: () =>
    useHttp<{ put: [{ id: number; is_follow: BooleanLike }, void] }>(
      "/user/users/{id}/follow"
    ),

  /**
   * Followers
   */
  useFollowers: (page: number) => {
    const http = useHttp<{
      get: [PaginationQuery, PaginationResponse<{ Follower: Follower }>];
    }>("/user/followers/followers", {
      suspense: true,
    });

    const arg = useMemo(() => (page ? { page } : null), [page]);
    return useGet(http, arg);
  },
  useFollowings: (page: number) => {
    const http = useHttp<{
      get: [PaginationQuery, PaginationResponse<{ Following: Following }>];
    }>("/user/followers/followings", {
      suspense: true,
    });

    const arg = useMemo(() => (page ? { page } : null), [page]);
    return useGet(http, arg);
  },

  useUserRate: (userId: number | undefined, page: number | undefined) => {
    const http = useHttp<{
      get: [GetUserRateRequest, GetUserRateResponse];
    }>("/public/users/{id}/rating", {
      suspense: true,
    });

    const arg = useMemo(() => (page && userId ? { page, id: userId } : null), [
      page,
      userId,
    ]);
    return useGet(http, arg);
  },
};
