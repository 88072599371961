import { UserRead } from "../../other/interfaces";
import { getActions } from "../../utils/tools";

export type UserAction =
  | {
      type: "LOGIN";
      payload: UserRead;
    }
  | {
      type: "VERIFY_MOBILE";
    }
  | {
      type: "UPDATE_PROFILE";
      payload: Partial<UserRead>;
    }
  | {
      type: "CHANGE_PHOTO";
      payload: string;
    }
  | {
      type: "VERIFY_EMAIL";
    }
  | {
      type: "SKIP_INTERESTS_SETUP" | "SKIP_ALLERGIES_SETUP";
    }
  | {
      type: "SEE_WELCOME";
    }
  | {
      type: "SKIP_EMAIL_VERIFY";
    }
  | {
      type: "BECOME_COOK";
      payload: { iban: string; id_number: string };
    }
  | {
      type: "BECOME_GOURMET";
    }
  | {
      type: "EMAIL_CODE_SEND" | "MOBILE_CODE_SEND";
      payload: Date;
    }
  | {
      type: "COMPLETE_PROFILE";
    }
  | {
      type: "LOGOUT";
      payload?: any;
    };

export const userActions = getActions<UserAction>()({
  verifyCodeSend: (type: "email" | "mobile") => ({
    type: type === "email" ? "EMAIL_CODE_SEND" : "MOBILE_CODE_SEND",
    payload: new Date(),
  }),
  verifyMobile: () => ({ type: "VERIFY_MOBILE" }),
  changeProfilePhoto: (payload: string) => ({ type: "CHANGE_PHOTO", payload }),
  verifyEmail: () => ({ type: "VERIFY_EMAIL" }),
  skipAllergiesSetup: () => ({ type: "SKIP_ALLERGIES_SETUP" }),
  skipInterestsSetup: () => ({ type: "SKIP_INTERESTS_SETUP" }),
  skipEmailVerify: () => ({ type: "SKIP_EMAIL_VERIFY" }),
  logout: () => ({ type: "LOGOUT" }),
  seeWelcome: () => ({ type: "SEE_WELCOME" }),
  login: (payload: UserRead) => ({ type: "LOGIN", payload }),
  becomeCook: (payload: { iban: string; id_number: string }) => ({
    type: "BECOME_COOK",
    payload,
  }),
  becomeGourmet: () => ({ type: "BECOME_GOURMET" }),
  updateProfile: (payload: Partial<UserRead>) => ({
    type: "UPDATE_PROFILE",
    payload,
  }),
  completeProfile: () => ({
    type: "COMPLETE_PROFILE",
  }),
} as const);
