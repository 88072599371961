/* eslint-disable no-unused-vars */
import {
  DeliveryMethodRelation,
  DinnerType,
} from "../pages/Dinner/dinner.validators";

export enum UserType {
  // eslint-disable-next-line no-unused-vars
  Cook = "cook",
  // eslint-disable-next-line no-unused-vars
  Gourmet = "gourmet",
}

export type LangCode = "en" | "ru" | "ge";

export type PropByLang<P extends string, V = string> = Record<
  `${P}_${LangCode}`,
  V
>;

export interface Country {
  id: number;
  name: number;
  parent_id: number | null;
}

export enum NotificationType {
  OrderAccepted = "order_accepted",
  OrderRejected = "order_rejected",
  OrderOffered = "order_offered",
  OrderCompleted = "order_completed",
  FollowerStartedCooking = "cook_started_by_follower",
  OrderRated = "order_rated",
}

export type Notification = {
  id: number;
  dinner_id: undefined | number | null;
  created_at: string;
  seen: BooleanLike;
} & (
  | {
      type: NotificationType.OrderOffered;
      order_id: number;
      User: Pick<UserBase, "id" | "name">;
      DinnerDetail: {
        dinner_id: number;
        name: string;
        unit_type: string;
      };
      Order: {
        quantity: number;
      };
    }
  | {
      type: NotificationType.OrderCompleted;
      order_id: number;
      Author: Pick<UserBase, "id" | "name">;
      DinnerDetail: {
        dinner_id: number;
        name: string;
        unit_type: string;
      };
      Order: {
        quantity: number;
      };
    }
  | {
      type: NotificationType.OrderAccepted | NotificationType.OrderRejected;
      order_id: number;
      Author: Pick<UserBase, "id" | "name">;
      DinnerDetail: {
        dinner_id: number;
        name: string;
        unit_type: string;
      };
      Order: {
        quantity: number;
      };
    }
  | {
      type: NotificationType.FollowerStartedCooking;
      dinner_id: number;
      user_id: number;
      User: Pick<UserBase, "id" | "name">;
      Author: Pick<UserBase, "id" | "name">;
      DinnerDetail: {
        dinner_id: number;
        name: string;
        unit_type: string;
      };
    }
  | {
      type: NotificationType.OrderRated;
      user_id: number;
      order_id: number;
    }
);

export interface Picture {
  name: string;
  id: number;
  created_at: string;
  deleted_at: null;
  updated_at: string;
}

export interface MealCategory extends PropByLang<"name"> {
  id: number;
  category_id: number;
}

export interface MealIngredient {
  id: number;
  name: string;
  ingredient_id: number;
}

export interface UploadedFile {
  public_url?: string;
  file_name: string;
}

export interface UserBase {
  id: number;
  email: string;
  mobile: string;
  name: string;
  lastname: string;
  address: string;
  lat: number;
  lng: number;
  city: string;
  country_id: number;
  iban: string | null;
  id_number: string | null;
  about_me?: string | null;
  profile_image: string | null;
  AllergiesChecked: number[] | null;
  CategoryChecked: number[] | null;
  complited: "complited" | "incomplited";
}

export interface UserRead extends UserBase {
  verify_mobile: BooleanLike;
  verify_email: BooleanLike | -1;
  welcome_seen: boolean;
  is_cook: BooleanLike | -1;
  identity_confirmed: BooleanLike | -1;
  updated_at: string;
  created_at: string;
}

export interface UserWrite extends UserBase {}

interface RecipeBase extends Rating {
  id: number;
  title: string;
  is_public: BooleanLike;
  cook_time: number;
  description: string;
  video: string | null;
}

export interface RecipeWrite extends RecipeBase {
  categories: number[];
  pictures: string[] | null;
}

export interface RecipeRead extends RecipeBase {
  categories: (MealCategory | undefined)[];
  pictures: (Picture | undefined)[] | null;
  created_at: string;
  author: Pick<UserRead, "id" | "name" | "lastname">;
}

export type RecipeForm = Exclude<RecipeRead, "author_id" | "id" | "views">;

export interface DinnerBase {
  id: number;
  name: string;
  cook_time: number;
  unit_type: "portion" | "piece";
  weight: number;
  quantity: number;
  unit_price: number;
  type: DinnerType;
  video: string | null;
  delivery_price: number | undefined;
}

export interface DinnerRead extends DinnerBase {
  categories: (Omit<MealCategory, "name"> | undefined)[];
  ingredients: (Omit<MealIngredient, "name"> | undefined)[];
  delivery_method: (Omit<DeliveryMethodRelation, "name"> | undefined)[];
  author: Pick<
    UserRead,
    "id" | "name" | "lastname" | "profile_image" | "address"
  >;
  pictures: (Picture | undefined)[];
  is_boosted?: boolean;
  created_at: string;
  lat: number;
  lng: number;
  address: string;
}

export interface DinnerWrite {
  categories: number[];
  ingredients: number[];
  delivery_method: number[];
  pictures: string[];
}

export interface SelectOption<T = any, D = unknown> {
  label: string;
  value: T;
  data?: D;
}

export interface Address {
  address: string;
  lat: number;
  lng: number;
}

export interface ApiCustomErrorResponse<Err extends string = string> {
  error: Err;
  errors?: undefined;
}

export interface ApiFieldsErrorResponse<Err extends string[] = string[]> {
  error?: undefined;
  errors: Record<Err[number], string[]>;
}

export interface PaginationQuery {
  page: number;
}

export interface PaginationResponse<T = unknown> {
  data: T[];
  last_page: number;
}

export interface Rating {
  rating: number;
  votes: number;
}

export interface Follower {
  id: number;
  name: string;
  lastname: string;
  order_count: number;
  profile: string;
}

export type Following = Follower;

export interface OGConfig {
  url: string;
  type: "article";
  title: string;
  description: string;
  image?: string;
}

export interface MediaItem {
  type: "video" | "image";
  src: string;
}
