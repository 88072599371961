import React, { useEffect, useMemo } from "react";
import "./i18n";
import "./assets/styles/app.scss";
import { AppRouter } from "./Router";
import { Provider as StoreProvider } from "react-redux";
import { store } from "./store";
import { Provider as FetchProvider } from "use-http";
import { useJsApiLoader } from "@react-google-maps/api";
import { fetchOptions, GOOGLE_MAP_API_KEY, sentryOptions } from "./config";
import i18n from "i18next";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";

function App() {
  const API_URL = process.env.REACT_APP_API_URL;

  // Initialize Sentry
  Sentry.init(sentryOptions);

  // Load google maps api
  const { isLoaded: googleLoaded, loadError } = useJsApiLoader(
    useMemo(() => {
      return {
        libraries: ["places"],
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
        language: i18n.language,
      };
    }, [])
  );

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (!e.target) return;
      const element = e.target as HTMLElement;
      if (
        element.tagName === "A" &&
        (element as any).attributes?.href?.nodeValue === "#"
      ) {
        e.preventDefault();
      }
    });
  }, []);

  // Initialize google analytics
  useEffect(() => {
    const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (gaId) ReactGA.initialize(gaId);
  }, []);

  if (loadError) throw loadError;
  if (!googleLoaded) return null;

  return (
    <FetchProvider url={API_URL} options={fetchOptions}>
      <StoreProvider store={store}>
        <AppRouter />
      </StoreProvider>
    </FetchProvider>
  );
}

export default App;
