import React, { useEffect, useMemo, useState } from "react";
import { CustomIcon } from "../assets/icons";
import { TagSelect, TagSelectProps } from "./common";
import { Props as ReactSelectProps } from "react-select";
import { SelectOption } from "../other/interfaces";
import { useLatest, useNumber } from "react-use";
import { generalApiHooks } from "../api/general";
import { useTranslation } from "react-i18next";
import { useCookingTimeOptions } from "../pages/Dinner/dinner.hooks";
import Form from "react-bootstrap/esm/Form";
import { useLang, useMiniCallback } from "../other/hooks";
import { getPropByLang, handleUnknownError } from "../utils/other";
import { Select } from "./inputs/Select";

interface NumberInputProps {
  size?: "md" | "sm";
  className?: string;
  defaultValue: number;
  onChange: (v: number) => void;
  min?: number;
  max?: number;
  step: number;
  formatValue?: (v: number) => React.ReactNode;
  value?: number;
}
export const NumberInputWithSteps = React.memo<NumberInputProps>(
  React.forwardRef(({ formatValue, ...props }, ref) => {
    const {
      size = "md",
      className = "",
      defaultValue,
      value: forceValue,
    } = props;
    const [MIN, MAX, STEP] = [props.min, props.max, props.step];
    const [value, valueFns] = useNumber(defaultValue, MAX, MIN);
    const propsRef = useLatest(props);

    useEffect(() => propsRef.current.onChange(value), [value, propsRef]);
    useEffect(() => {
      if (typeof forceValue === "number") valueFns.set(forceValue);
    }, [forceValue, valueFns]);
    return (
      <div className={`number-input number-input--${size} ${className}`}>
        <button
          type="button"
          className="btn"
          onClick={() => valueFns.dec(STEP)}
        >
          <CustomIcon.Minus className="icon" />
        </button>
        <span className="value">{formatValue?.(value) ?? value}</span>
        <button
          type="button"
          className="btn"
          onClick={() => valueFns.inc(STEP)}
        >
          <CustomIcon.Plus className="icon" />
        </button>
      </div>
    );
  })
);

export const DistanceInput = React.memo<{
  size?: "md" | "sm";
  className?: string;
  defaultValue?: number;
  onChange: (v: number) => void;
}>(
  React.forwardRef((props, ref) => {
    const [t] = useTranslation();
    const { size = "md", defaultValue = 1500 } = props;

    const formatValue = useMiniCallback((value: number) => (
      <>
        {value / 1000} {t("general.kilometers_short")}
      </>
    ));
    const handleChange = useMiniCallback((value: number) => {
      props.onChange(value / 1000);
    });
    return (
      <NumberInputWithSteps
        className={`distance-input distance-input--${size}`}
        defaultValue={defaultValue}
        min={500}
        step={500}
        formatValue={formatValue}
        onChange={handleChange}
      />
    );
  })
);

export const MealCategoriesSelect = React.forwardRef<
  ReactSelectProps,
  Omit<ReactSelectProps, "options">
>((props, ref) => {
  const { categories, http } = generalApiHooks.useMealCategories();
  const createCategoryReq = generalApiHooks.useCreateMealCategory();

  const lang = useLang();
  const options: SelectOption<number>[] | undefined = useMemo(
    () =>
      categories?.map((c) => ({
        label: getPropByLang(c, "name", lang),
        value: c.id,
      })),
    [categories, lang]
  );

  const handleCreate = useMiniCallback(
    (option: SelectOption<number>): Promise<SelectOption<number>> =>
      createCategoryReq
        .post({ name: option.label })
        .then((createdCategory) => {
          http.cache.clear();
          return {
            value: createdCategory.id,
            label: getPropByLang(createdCategory, "name", lang),
          };
        })
        .catch((err) => {
          handleUnknownError(err);
          throw err;
        })
  );

  if (!options) return null;
  return (
    <Select
      isMulti
      options={options}
      {...props}
      ref={ref as any}
      creatable
      onCreate={handleCreate}
      isLoading={createCategoryReq.loading}
    />
  );
});

export const MealIngredientsSelect = React.forwardRef<
  ReactSelectProps,
  Omit<ReactSelectProps, "options">
>((props, ref) => {
  const { ingredients, http } = generalApiHooks.useMealIngredients();
  const createIngredientReq = generalApiHooks.useCreateMealIngredient();

  const options: SelectOption<number>[] | undefined = useMemo(() => {
    return ingredients?.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  }, [ingredients]);

  const handleCreate = useMiniCallback(
    (option: SelectOption<number>): Promise<SelectOption<number>> =>
      createIngredientReq
        .post({ name: option.label })
        .then((createdIngredient) => {
          http.cache.clear();
          return {
            value: createdIngredient.id,
            label: createdIngredient.name,
          };
        })
        .catch((err) => {
          handleUnknownError(err);
          throw err;
        })
  );
  if (!options) return null;
  return (
    <Select
      options={options}
      {...props}
      ref={ref as any}
      isMulti
      creatable
      onCreate={handleCreate}
      isLoading={createIngredientReq.loading}
    />
  );
});

export const DeliveryMethodsSelect = React.forwardRef<
  TagSelectProps,
  Omit<TagSelectProps, "options">
>((props, ref) => {
  const { deliveryMethods } = generalApiHooks.useDeliveryMethods();
  const options: SelectOption<number>[] | undefined = useMemo(
    () => deliveryMethods?.map((c) => ({ label: c.name, value: c.id })),
    [deliveryMethods]
  );
  if (!options) return null;
  return <TagSelect options={options} itemClassname="tag--sm" {...props} />;
});

export const CookingTimeInput = React.memo(
  React.forwardRef<
    HTMLInputElement,
    {
      onChange: (v: number) => void;
      defaultValue?: number;
      error?: string | undefined;
    }
  >((props, ref) => {
    const { onChange, defaultValue, error } = props;

    const [t] = useTranslation();
    const cookingTimeOptions = useCookingTimeOptions();
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      if (typeof value !== "undefined") onChange(value);
    }, [onChange, value]);

    return (
      <Form.Group>
        <Form.Label>{t("pages.save_dinner.cooking_time")} *</Form.Label>
        <div className="d-flex flex-column flex-sm-row align-items-sm-center ">
          <TagSelect
            options={cookingTimeOptions}
            itemClassname="tag--sm"
            onChange={setValue}
            value={value}
          />
          <Form.Control
            autoComplete="off"
            type="number"
            value={value || ""}
            onChange={(e) => setValue(parseInt(e.target.value))}
            className="ml-2 flex-1 mt-2 mt-sm-0"
            placeholder={t("general.minutes_short")}
            ref={ref}
          />
        </div>
        <Form.Control.Feedback type="invalid" className="d-block">
          {error}
        </Form.Control.Feedback>
      </Form.Group>
    );
  })
);
