import { AxiosInstance } from "axios";
import { Socket } from "socket.io-client";
import { ChatContact, ChatMessage } from "./chat.interfaces";
import { ListenEvents } from "./socket.interfaces";
import { getHttpInstance, getSocketConnection } from "./communication";

export class ChatService {
  static readonly isInitialized = false;
  static readonly ws: Socket<ListenEvents>;
  static readonly http: AxiosInstance = getHttpInstance();

  static initialize() {
    return new Promise<void>((resolve, reject) => {
      if (ChatService.isInitialized) return resolve();
      getSocketConnection()
        .then((ws) => {
          const token = localStorage.getItem("ACCESS_TOKEN") || "";
          (ChatService as any).ws = ws;
          (ChatService as any).isInitialized = true;
          ChatService.http.defaults.headers.common.authorization = token;
          resolve();
        })
        .catch(reject);
    });
  }

  static AssertInitialized() {
    if (!ChatService.isInitialized)
      throw new Error("ChatService not initialized");
  }

  /**
   * HTTP Requests
   */
  static async getMessages(
    room: number,
    page?: number
  ): Promise<ChatMessage[]> {
    ChatService.AssertInitialized();
    return ChatService.http
      .get("/messages", { params: { room, page } })
      .then((res) => res.data);
  }

  static async getContacts(): Promise<ChatContact[]> {
    ChatService.AssertInitialized();
    return ChatService.http.get("/contacts").then((res) => res.data);
  }

  static async sendMessage(
    room: number,
    message: string
  ): Promise<ChatMessage> {
    ChatService.AssertInitialized();
    return ChatService.http
      .post("/messages", { room, message })
      .then((res) => res.data);
  }

  static async markMessagesAsSeen(
    messages: number[],
    room: number
  ): Promise<ChatMessage> {
    ChatService.AssertInitialized();
    return ChatService.http
      .post("/messages/seen", { messages, room })
      .then((res) => res.data);
  }

  /**
   * SocketIO listeners
   */
  static onNewContact(
    listener: (contact: ChatContact | ChatContact[]) => void
  ): void {
    ChatService.AssertInitialized();
    ChatService.ws.on(
      "new-contact",
      (data: { contact: ChatContact | ChatContact[] }) => {
        listener(data.contact);
      }
    );
  }

  static onMessage(
    toListenRoom: number | undefined,
    listener: (message: ChatMessage) => void
  ): void {
    ChatService.AssertInitialized();
    ChatService.ws.on("incoming-message", (data: ChatMessage) => {
      if (toListenRoom !== undefined && data.room !== toListenRoom) return;
      listener(data);
    });
  }

  static removeSocketListener(ev?: keyof ListenEvents, listener?: Function) {
    ChatService.ws.removeListener(ev, listener as any);
  }

  static onMessagesSeen(
    toListenRoom: number | undefined,
    listener: (arg: Parameters<ListenEvents["messages-seen"]>[0]) => void
  ): void {
    ChatService.AssertInitialized();
    ChatService.ws.on(
      "messages-seen",
      (data: Parameters<ListenEvents["messages-seen"]>[0]) => {
        console.debug("DEBUG toListenRoom", toListenRoom);
        console.debug("DEBUG data.room", data.room);
        if (toListenRoom !== undefined && data.room !== toListenRoom) return;
        listener(data);
      }
    );
  }
}
