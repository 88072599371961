import React, { useEffect, useLayoutEffect, useState } from "react";
import { Notification, NotificationType } from "../../other/interfaces";
import { Button, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomIcon, FeatherIcon } from "../../assets/icons";
import { notificationApiHooks } from "../../api/notifications";
import { useIntersection, useLatest, useToggle } from "react-use";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { getThrottledCallback, handleUnknownError } from "../../utils/other";
import { Trans, useTranslation } from "react-i18next";
import { LoaderBox } from "../General";
import {
  useFormatDate,
  useMiniCallback,
  usePagination,
} from "../../other/hooks";
import classNames from "classnames";
import { alertConfirm } from "../../utils/alerts";

export const NotificationsDropdown = React.memo<{ className?: string }>(
  ({ className }) => {
    const [show, toggleShow] = useToggle(false);
    const {
      count,
      refresh,
    } = notificationApiHooks.useListenForNotificationsCount();
    return (
      <Dropdown
        className="notifications-dropdown curve-dropdown"
        show={show}
        onToggle={toggleShow}
      >
        <Dropdown.Toggle
          id="notificationsDropdown"
          as={React.forwardRef<any, any>((p, ref) => (
            <div ref={ref} {...p}>
              <Button className={`btn-icon ${className}`}>
                <FontAwesomeIcon icon={faBell} />
                {!!count && (
                  <span className="count">{count > 9 ? "9+" : count}</span>
                )}
              </Button>
            </div>
          ))}
        />

        <Dropdown.Menu className="notifications">
          <div className="triangle" />
          {show && <NotificationsList onClear={refresh} />}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
const NotificationsList = React.memo<{
  onClear: () => void;
}>(({ onClear }) => {
  const [t] = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>();
  const pagination = usePagination();
  const { response, isLoading } = notificationApiHooks.useLatestNotifications(
    pagination.currentPage
  );
  const paginationRef = useLatest(pagination);
  pagination.onPageCountChange(response?.last_page);

  const intersectionRootRef = React.useRef(null);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: intersectionRootRef.current,
    rootMargin: "30px",
    threshold: 1,
  });

  // Increase page;
  useEffect(() => {
    if (!intersection?.isIntersecting) return;
    const { currentPage, onPageChange } = paginationRef.current;
    onPageChange(currentPage + 1);
  }, [intersection, paginationRef]);

  // Push current page to array
  useEffect(() => {
    if (!response) return;
    setNotifications((current) => [...(current ?? []), ...response.data]);
  }, [response]);

  // Mark notifications as "seen"
  const markAsSeenReq = useLatest(
    notificationApiHooks.useMarkSeenNotifications()
  );
  useLayoutEffect(() => {
    if (!response) return;
    // Ids of unseen notifications
    const ids = response.data.filter((n) => !n.seen).map((n) => n.id);
    const cb = getThrottledCallback(() => {
      setNotifications((notifications) =>
        notifications?.map((n) => (ids.includes(n.id) ? { ...n, seen: 1 } : n))
      );
    }, 700);
    if (ids.length) {
      markAsSeenReq.current
        .put({ ids })
        .then(cb)
        .catch((err) => handleUnknownError(err));
    }
  }, [markAsSeenReq, response]);

  // Clear seen notifications
  const clearReq = notificationApiHooks.useDeleteNotifications();
  const handleClear = useMiniCallback(() => {
    if (!response) return;
    const ids = response.data.filter((n) => n.seen).map((n) => n.id);
    const handleDelete = () =>
      clearReq
        .delete({ ids })
        .then(() => {
          setNotifications((notifications) =>
            notifications?.filter((n) => !n.seen)
          );
        })
        .then(onClear)
        .catch((err) => handleUnknownError(err));
    alertConfirm(handleDelete, t("general.delete_confirm_question"));
  });
  return (
    <div className="notifications-list" ref={intersectionRootRef}>
      {/* Clear notifications */}
      {!!notifications?.length && (
        <div className="text-right mb-2 mx-2">
          <a href="#" className="text-gray" onClick={handleClear}>
            {t("widgets.notifications.clear")}
          </a>
        </div>
      )}

      <LoaderBox isLoading={isLoading}>
        {notifications?.map((notification) => (
          <React.Fragment key={notification.id}>
            <NotificationItem notification={notification} />
          </React.Fragment>
        ))}
        {notifications?.length ? (
          <div ref={intersectionRef} className="infinite-scroll-trigger" />
        ) : (
          <div className="text-center">
            {t("features.notifications.zero_notifications_text")}
          </div>
        )}
      </LoaderBox>
    </div>
  );
});
const NotificationItem = React.memo<{
  notification: Notification;
}>(({ notification }) => {
  const [t] = useTranslation();
  const formatDate = useFormatDate();
  const href = (() => {
    if (
      notification.type === NotificationType.OrderRejected ||
      notification.type === NotificationType.OrderAccepted ||
      notification.type === NotificationType.OrderOffered ||
      notification.type === NotificationType.OrderCompleted
    ) {
      return "/user/orders/" + notification.order_id;
    }
    if (notification.type === NotificationType.FollowerStartedCooking) {
      return `/dinners/${notification.dinner_id}/details`;
    }
    if (notification.type === NotificationType.OrderRated) {
      return `/users/${notification.user_id}`;
    }
    return "#";
  })();
  return (
    <Dropdown.Item
      as={Link}
      to={href}
      className={classNames(
        "notification",
        !notification.seen && "notification--unseen"
      )}
    >
      <span className="notification__date">
        {formatDate(notification.created_at)}
      </span>
      <div className="d-flex">
        <span className="notification__icon">
          {notification.seen ? <FeatherIcon.Eye /> : <CustomIcon.Check />}
        </span>
        <span className="notification__title">
          {notification.type === NotificationType.OrderRejected && (
            <Trans
              i18nKey="widgets.notifications.order_rejected.title"
              values={{
                cook_name: notification.Author.name,
                quantity: notification.Order.quantity,
                dinner_name: notification.DinnerDetail.name,
                unit_type: t(
                  "general.dinner_unit_type." +
                    notification.DinnerDetail.unit_type
                ),
              }}
            />
          )}
          {notification.type === NotificationType.OrderAccepted && (
            <Trans
              i18nKey="widgets.notifications.order_accepted.title"
              values={{
                cook_name: notification.Author.name,
                quantity: notification.Order.quantity,
                dinner_name: notification.DinnerDetail.name,
                unit_type: t(
                  "general.dinner_unit_type." +
                    notification.DinnerDetail.unit_type
                ),
              }}
            />
          )}
          {notification.type === NotificationType.OrderCompleted && (
            <Trans
              i18nKey="widgets.notifications.order_completed.title"
              values={{
                cook_name: notification.Author.name,
                unit_type: t(
                  "general.dinner_unit_type." +
                    notification.DinnerDetail.unit_type
                ),
                quantity: notification.Order.quantity,
                dinner_name: notification.DinnerDetail.name,
              }}
            />
          )}
          {/*  OrderOffered */}
          {notification.type === NotificationType.OrderOffered && (
            <Trans
              i18nKey="widgets.notifications.order_offered.title"
              values={{
                client_name: notification.User.name,
                quantity: notification.Order.quantity,
                dinner_name: notification.DinnerDetail.name,
                unit_type: t(
                  "general.dinner_unit_type." +
                    notification.DinnerDetail.unit_type
                ),
              }}
            />
          )}
          {/*  OrderOffered */}
          {notification.type === NotificationType.FollowerStartedCooking && (
            <Trans
              i18nKey="widgets.notifications.follower_started_cooking.title"
              values={{
                cook_name: notification.Author.name,
              }}
            />
          )}
          {/*  Rate Received */}
          {notification.type === NotificationType.OrderRated && (
            <Trans
              i18nKey="widgets.notifications.order_rated.title"
              values={{}}
            />
          )}
        </span>
      </div>
    </Dropdown.Item>
  );
});
