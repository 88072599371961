import {useGet, useHttp} from "../other/use-http";
import {useMiniCallback} from "../other/hooks";
import {useEffectOnce, useInterval} from "react-use";
import {useMemo, useState} from "react";
import {Notification, PaginationQuery, PaginationResponse,} from "../other/interfaces";
import {handleUnknownError} from "../utils/other";

export const notificationApiHooks = {
    useListenForNotificationsCount: () => {
        const [response, setResponse] = useState<{ count: number }>();
        const http = useHttp<{ get: [void, { count: number }] }>(
            "/user/notifications/unseen/count"
        );
        const handleRefresh = useMiniCallback(() => {
            http
                .get()
                .then(setResponse)
                .catch((err) => handleUnknownError(err));
        });

        useInterval(handleRefresh, 30 * 1000);
        useEffectOnce(handleRefresh);

        return useMemo(
            () => ({
                count: response?.count,
                isLoading: http.loading,
                refresh: handleRefresh,
            }),
            [http.loading, response?.count, handleRefresh]
        );
    },

    useLatestNotifications: (page: number) => {
        const http = useHttp<{
            get: [PaginationQuery, PaginationResponse<Notification>];
        }>("/user/notifications");

        const arg = useMemo(() => (page ? {page} : null), [page]);

        return useGet(http, arg);
    },

    useMarkSeenNotifications: () =>
        useHttp<{ put: [{ ids: number[] }, void] }>("/user/notifications/seen"),

    useDeleteNotifications: () =>
        useHttp<{ delete: [{ ids: number[] }, void] }>("/user/notifications"),
};
