import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { CustomIcon, FeatherIcon } from "../assets/icons";
// @ts-ignore
import ReactStarRatings from "react-star-ratings";
import { UseFormReturn } from "react-hook-form";
import { useCountries, useMiniCallback } from "../other/hooks";
import { useLatest } from "react-use";
import { SelectOption } from "../other/interfaces";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { getImageSrc } from "../utils/other";
import _ from "lodash";
import { Select } from "./inputs/Select";

export const Breadcrumbs: React.FC<{
  className?: string;
  items: {
    title: string;
    link?: string;
    active?: boolean;
  }[];
}> = ({ items, className }) => {
  const onClick = (e: React.MouseEvent, item: typeof items[0]) => {
    if (!item.link) {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <nav className={`breadcrumbs ${className ?? ""}`}>
      {items.map((item, idx) => (
        <React.Fragment key={item.title}>
          <a
            href={item.link || "#"}
            className={classNames("breadcrumbs__item", item.active && "active")}
            onClick={(e) => onClick(e, item)}
          >
            {item.title}
            {items.length > 1 && idx !== items.length - 1 && (
              <FeatherIcon.ChevronRight className="breadcrumbs__delimiter" />
            )}
          </a>
        </React.Fragment>
      ))}
    </nav>
  );
};

export const RichTextEditor = React.memo<{
  defaultValue?: string;
  value?: string;
  onChange: (html: string) => void;
  className?: string;
}>(({ defaultValue = "", onChange, className = "" }) => {
  return (
    <ReactQuill
      theme="snow"
      defaultValue={defaultValue}
      onChange={onChange}
      className={`rich-editor ${className}`}
      modules={{
        toolbar: [
          ["bold", "italic", "underline", "strike", { color: [] }], // toggled buttons
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        ],
      }}
    />
  );
});

export type TagSelectProps<T = any, D = unknown> = {
  options: SelectOption<T, D>[];
  className?: string;
  itemClassname?: string;
} & (
  | { value: T | undefined; isMulti?: false; onChange: (v: T) => void }
  | { value: T[] | undefined; isMulti: true; onChange: (v: T[]) => void }
);

export const TagSelect = React.forwardRef<HTMLDivElement, TagSelectProps>(
  (props, ref) => {
    const { options, className, itemClassname } = props;
    const handleClick = (
      option: SelectOption<unknown>,
      wasSelected: boolean
    ) => {
      console.debug("handleClick", option, wasSelected);
      let nextValue: typeof props.value;
      if (props.isMulti) {
        if (wasSelected) {
          nextValue = props.value?.filter((v) => v !== option.value);
        } else nextValue = [...(props.value ?? []), option.value];
      } else {
        nextValue = option.value;
      }
      props.onChange(nextValue);
    };
    return (
      <div className={classNames("tag-select", className)}>
        {options.map((option) => {
          const isSelected = props.isMulti
            ? !!props.value?.includes(option.value)
            : props.value === option.value;
          return (
            <span
              key={option.label}
              onClick={() => handleClick(option, isSelected)}
              className={classNames(
                itemClassname,
                "tag",
                `tag--warning`,
                isSelected && "selected"
              )}
            >
              {option.label}
            </span>
          );
        })}
      </div>
    );
  }
);

export const UserProfilePhoto: React.FC<{
  src: string | undefined | null;
  className?: string;
}> = ({ src, className }) => (
  <div className={classNames("profile-photo", className)}>
    {src && <img src={getImageSrc(src, "profile")} alt="" />}
    {!src && <FeatherIcon.User className="icon" />}
  </div>
);

export const StarRatings = React.memo<{
  rating: number | undefined;
  numberOfStars?: number;
  name?: string;
  starSpacing?: number;
  starDimension?: string;
  starHoverColor?: string;
  changeRating?: (value: number) => void;
}>(({ rating, name = "rating", numberOfStars = 5, ...rest }) => (
  <ReactStarRatings
    rating={rating}
    starRatedColor="#FEB601"
    starEmptyColor="transparent"
    starHoverColor="#FEB601"
    numberOfStars={numberOfStars}
    starSpacing={`${2}px`}
    name={name}
    {...rest}
  />
));

export const CountryAndCitySelect = React.memo<{
  onChange: (v: { city: string; country_id: number }) => void;
  form: UseFormReturn<any>;
  cityDisabled?: boolean;
  countryDisabled?: boolean;
}>(function CountryAndCitySelect({
  onChange,
  form,
  countryDisabled,
  cityDisabled,
}) {
  const [t] = useTranslation();
  const externalValueRef = useLatest(form.getValues());
  const dispatchTimerRef = useRef<any>();
  const { countries } = useCountries();

  const [selectedCountry, setSelectedCountry] = useState<number>(
    externalValueRef.current.country_id
  );
  const [selectedCity, setSelectedCity] = useState<string>(
    externalValueRef.current.city
  );
  const countryOptions = useMemo(
    () => countries?.map((c) => ({ label: c.name, value: c.id })),
    [countries]
  );
  const onChangeFn = useLatest(onChange);
  const handleCityChange = useMiniCallback((value: string) => {
    if (dispatchTimerRef.current) clearTimeout(dispatchTimerRef.current);
    dispatchTimerRef.current = setTimeout(() => setSelectedCity(value), 50);
  });
  useEffect(() => {
    const currentValue = {
      country_id: externalValueRef.current.country_id,
      city: externalValueRef.current.city,
    };
    const nextValue = { country_id: selectedCountry, city: selectedCity };
    if (!_.isEqual(currentValue, nextValue)) onChangeFn.current(nextValue);
  }, [externalValueRef, onChangeFn, selectedCity, selectedCountry]);

  const { errors } = form.formState;
  form.register("country_id");
  return (
    <div className="row">
      <Form.Group controlId="country_id" className="col-6">
        <Form.Label>{t("general.country")} *</Form.Label>
        <Select
          value={selectedCountry}
          className={errors.country_id && "is-invalid"}
          options={countryOptions}
          onChange={setSelectedCountry}
          isDisabled={countryDisabled}
        />
        <Form.Control.Feedback type="invalid">
          {errors.country_id?.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="city" className="col-6">
        <Form.Label>{t("general.city")} *</Form.Label>
        <Form.Control
          autoComplete="off"
          type="text"
          isInvalid={!!errors.city}
          {...form.register("city")}
          onChange={(event) => {
            handleCityChange(event.target.value);
          }}
          defaultValue={externalValueRef.current.city}
          readOnly={cityDisabled}
        />
        <Form.Control.Feedback type="invalid">
          {errors.city?.message}
        </Form.Control.Feedback>
      </Form.Group>
    </div>
  );
});

interface InPageTabsProps<T = any> {
  tabs: { label: string; value: T }[];
  value: T | undefined;
  onChange: (v: T) => void;
  className?: string;
}

export const InPageTabs: React.FC<InPageTabsProps> = ({
  value,
  tabs,
  onChange,
  className,
}) => {
  return (
    <div className={classNames("in-page-tabs", className)}>
      {tabs.map((tab) => (
        <a
          key={tab.label}
          href="#"
          className={classNames({ tab: true, active: value === tab.value })}
          onClick={(e) => {
            e.preventDefault();
            onChange(tab.value);
          }}
        >
          {tab.label}
        </a>
      ))}
    </div>
  );
};

export const GoBackLink: React.FC<{
  href: string | "auto";
}> = ({ href }) => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <Link
      to={href}
      className="go-back-link"
      onClick={(e) => {
        if (href === "auto") {
          e.preventDefault();
          history.goBack();
        }
      }}
    >
      <FeatherIcon.ChevronLeft className="mr-2 align-bottom" />
      {t("general.back")}
    </Link>
  );
};

export const SearchInput = React.memo<{
  className?: string;
  onChange?: (v: string) => void;
  onSearch: () => void;
  placeholder?: string;
  isLoading?: boolean;
}>(({ className, onChange, onSearch, placeholder, isLoading }) => {
  const [t] = useTranslation();
  return (
    <div className={classNames("overflow-hidden search-input-box", className)}>
      <input
        autoComplete="off"
        type="text"
        className="form-control"
        placeholder={placeholder || t("general.search_field_placeholder")}
        onChange={(e) => onChange?.(e.target.value)}
        onKeyPress={(e) => {
          if (e.nativeEvent.key.toLowerCase() === "enter") onSearch();
        }}
      />
      <Button className="btn search-btn" onClick={() => onSearch()} variant="">
        {isLoading ? (
          <FeatherIcon.Loader className="icon spinner-loader" width={18} />
        ) : (
          <CustomIcon.Search className="icon text-primary" />
        )}
      </Button>
    </div>
  );
});

export const Amount: React.FC<{
  currency?: "gel";
  amount: number | undefined | null;
  className?: string;
  isLoading?: boolean;
}> = ({ amount, className, isLoading }) => {
  return (
    <span className={classNames("amount", className)}>
      {isLoading ? (
        <FeatherIcon.Loader className="spinner-loader" />
      ) : typeof amount === "number" ? (
        <>
          <span>{_.round(amount, 2)}</span>
          <i className="lari lari-normal" />
        </>
      ) : null}
    </span>
  );
};
