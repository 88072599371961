import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Lang } from "../utils/storage";
import { LanguageMessages, ValidationOptions } from "joi";
import { IS_DEV_MODE } from "../config";

// EN
import en from "./locales/en/translation.json";
import apiErrorsEN from "./locales/en/api-errors.json";
import JoiErrorsEN from "./locales/en/joi-errors.json";
// GE
import ge from "./locales/ge/translation.json";
import apiErrorsGE from "./locales/ge/api-errors.json";
import JoiErrorsGE from "./locales/ge/joi-errors.json";
// RU
import ru from "./locales/ru/translation.json";
import apiErrorsRU from "./locales/ru/api-errors.json";
import JoiErrorsRU from "./locales/ru/joi-errors.json";
import { LangCode } from "../other/interfaces";

const resources = {
  ge: {
    translation: ge,
    api_errors: apiErrorsGE,
    joi_errors: JoiErrorsGE,
  },
  en: {
    translation: en,
    api_errors: apiErrorsEN,
    joi_errors: JoiErrorsEN,
  },
  ru: {
    translation: ru,
    api_errors: apiErrorsRU,
    joi_errors: JoiErrorsRU,
  },
};

export const joiMessages: Record<LangCode, LanguageMessages> = {
  ge: JoiErrorsGE,
  en: JoiErrorsEN,
  ru: JoiErrorsRU,
};
export const joiPreferences: ValidationOptions = {
  messages: joiMessages as any,
  errors: {
    label: false,
    language: Lang.get(),
  },
  abortEarly: false,
  debug: false,
};

i18n.on("languageChanged", (lang) => {
  Lang.set(lang);
  joiPreferences.errors!.language = lang;
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: Lang.get(), // use en if detected lng is not available

    saveMissing: true, // send not translated keys to endpoint
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss,
    },
    missingInterpolationHandler: (text) => {
      const errorText = "Missing variable in interpolation in: " + text;
      if (IS_DEV_MODE) throw new Error(errorText);
      console.error(errorText);
    },

    react: {
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "b"],
      useSuspense: true,
    },
  });

export default i18n;
