import React, { ReactNode, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { GuestOnlyRoute, PagePreloader, UserRoute } from "./components/General";
import { ErrorBoundary, NotFoundPage } from "./pages/Errors";
import * as Sentry from "@sentry/react";
import { useMiniCallback } from "./other/hooks";
import { useHistory } from "react-router";
import ReactGA from "react-ga";
import { useLatest } from "react-use";

// NOTE DO NOT import pages directly, use React.lazy
// region page imports
const EmailVerifyScreen = React.lazy(
  () => import("./pages/User/Verify/VerifyEmail")
);
const MobileVerifyScreen = React.lazy(
  () => import("./pages/User/Verify/VerifyMobile")
);
const UploadPhotoPage = React.lazy(() => import("./pages/User/UploadPhoto"));
const UserWelcomePage = React.lazy(() => import("./pages/User/Welcome"));
const HomePage = React.lazy(() => import("./pages/Home"));
const LoginPage = React.lazy(() => import("./pages/Auth/Login"));
const RegisterPage = React.lazy(() => import("./pages/Auth/Register"));
const ForgotPasswordPage = React.lazy(
  () => import("./pages/Auth/ForgotPassword")
);
const RecipesPage = React.lazy(() => import("./pages/Recipes"));
const RecipeDetailsPage = React.lazy(
  () => import("./pages/Recipes/RecipeDetails")
);
const SearchPage = React.lazy(() => import("./pages/Search"));
const UserActivityPage = React.lazy(() => import("./pages/User/Activity"));
const UserKitchenPage = React.lazy(() => import("./pages/User/UserKitchen"));
const UserOrdersPage = React.lazy(() => import("./pages/User/Orders"));
const UserFollowersPage = React.lazy(() => import("./pages/User/Followers"));
const UserRecipesPage = React.lazy(() => import("./pages/User/Recipes"));
const SaveUserRecipePage = React.lazy(
  () => import("./pages/User/Recipes/Save")
);
const ViewUserPage = React.lazy(() => import("./pages/UserProfile"));
const UserSettingsPage = React.lazy(() => import("./pages/User/Settings"));
const ChooseUserProfilePage = React.lazy(
  () => import("./pages/User/ChooseProfile")
);
const CookCalculatorPage = React.lazy(() => import("./pages/Calculator"));
const GourmetSetupPage = React.lazy(
  () => import("./pages/Gourmet/GourmetSetup")
);
const OrderDetailsPage = React.lazy(
  () => import("./pages/User/Orders/ViewOrder")
);
const UserProfilePage = React.lazy(() => import("./pages/User/Profile"));
const CreateDinnerPage = React.lazy(
  () => import("./pages/Dinner/CreateDinnerPage")
);
const EditDinnerPage = React.lazy(
  () => import("./pages/Dinner/EditDinnerPage")
);
const ViewDinnerDetailsPage = React.lazy(
  () => import("./pages/Dinner/ViewDetails")
);
const DinnerRequestPage = React.lazy(
  () => import("./pages/Dinner/DinnerRequest")
);
const ContactPage = React.lazy(() => import("./pages/Contact"));
const HowItWorksPage = React.lazy(() => import("./pages/HowItWorks"));
const FAQPage = React.lazy(() => import("./pages/FAQ"));
const AboutUsPage = React.lazy(() => import("./pages/About"));
const SuccessOrderPage = React.lazy(
  () => import("./pages/User/Orders/OrderSuccess")
);
const FailedOrderPage = React.lazy(
  () => import("./pages/User/Orders/OrderFailed")
);
const RateOrderPage = React.lazy(() => import("./pages/User/Orders/RateOrder"));
const BoostDinnerPage = React.lazy(() => import("./pages/Dinner/Boost"));
const CompleteProfilePage = React.lazy(
  () => import("./pages/Auth/CompleteProfile")
);
const ChangePasswordPage = React.lazy(
  () => import("./pages/User/ChangePassword")
);
const TermsAndConditionsPage = React.lazy(
  () => import("./pages/TermsAndConditions")
);
// endregion

const GoogleAnalyticsWatcher = React.memo((props) => {
  const history = useLatest(useHistory());

  useEffect(() => {
    history.current.listen(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }, [history]);

  return <>{props.children}</>;
});

export const AppRouter = () => {
  const onError = useMiniCallback((err) => {
    throw err;
  });
  return (
    <BrowserRouter forceRefresh>
      <GoogleAnalyticsWatcher>
        <ErrorBoundary>
          <Sentry.ErrorBoundary onError={onError}>
            <Suspense fallback={<PagePreloader />}>
              <Switch>
                <GuestOnlyRoute path="/login" component={LoginPage} exact />
                <GuestOnlyRoute
                  path="/register"
                  component={RegisterPage}
                  exact
                />
                <GuestOnlyRoute
                  path="/forgot-password"
                  component={ForgotPasswordPage}
                  exact
                  forceLogout
                />
                {/* General */}
                <Route path="/" component={HomePage} exact />
                <Route path="/recipes" component={RecipesPage} exact />
                <Route
                  path="/recipes/:id"
                  component={RecipeDetailsPage}
                  exact
                />
                <Route path={"/search"} component={SearchPage} exact />
                <Route path="/contact" component={ContactPage} exact />
                <Route path="/how-it-works" component={HowItWorksPage} exact />
                <Route path="/faq" component={FAQPage} exact />
                <Route path="/about" component={AboutUsPage} exact />
                <Route
                  path="/terms-and-conditions"
                  component={TermsAndConditionsPage}
                  exact
                />
                <UserRoute path="/users/:id" component={ViewUserPage} exact />

                {/* Verification */}
                <UserRoute
                  path={"/user/verify-email"}
                  component={EmailVerifyScreen}
                  exact
                  allowUnverified
                />
                <UserRoute
                  path={"/user/verify-mobile"}
                  component={MobileVerifyScreen}
                  exact
                  allowUnverified
                />
                <UserRoute
                  path={"/user/complete-profile"}
                  component={CompleteProfilePage}
                  exact
                  allowUnverified
                />

                {/* User general Routes */}
                <UserRoute
                  path={"/user/welcome"}
                  component={UserWelcomePage}
                  exact
                />
                <UserRoute
                  path={"/user/upload-photo"}
                  component={UploadPhotoPage}
                  exact
                />
                <UserRoute
                  path={"/user/profile"}
                  component={UserProfilePage}
                  exact
                />
                <UserRoute
                  path={"/user/change-password"}
                  component={ChangePasswordPage}
                  exact
                />
                <UserRoute
                  path={"/user/recipes"}
                  component={UserRecipesPage}
                  exact
                />
                <UserRoute
                  path={[
                    "/user/recipes/create",
                    "/user/recipes/:id(\\d+)/update",
                  ]}
                  component={SaveUserRecipePage}
                  exact
                />
                <UserRoute
                  path={"/user/:id(d+)"}
                  component={ViewUserPage}
                  exact
                />
                <UserRoute
                  path={["/user/activity", "/user"]}
                  component={UserActivityPage}
                  exact
                  allowUnverified
                />
                <UserRoute
                  path={"/user/orders/:tab(incoming|outgoing)?"}
                  component={UserOrdersPage}
                  exact
                />
                <UserRoute
                  path={"/orders/:id/success"}
                  component={SuccessOrderPage}
                  exact
                />
                <UserRoute
                  path={"/orders/fail"}
                  component={FailedOrderPage}
                  exact
                />
                <UserRoute
                  path={"/user/orders/:id(\\d+)/rate"}
                  component={RateOrderPage}
                  exact
                />

                <UserRoute
                  path={"/user/settings"}
                  component={UserSettingsPage}
                  exact
                />

                {/* Cook Routes */}
                <UserRoute
                  path={"/user/kitchen"}
                  component={UserKitchenPage}
                  exact
                />
                <UserRoute
                  path={[
                    "/user/followers/",
                    "/user/followers/:page(followers|followings)",
                  ]}
                  component={UserFollowersPage}
                  exact
                />
                <UserRoute
                  path={"/user/choose-profile"}
                  component={ChooseUserProfilePage}
                  exact
                />
                <UserRoute
                  path={"/calculator"}
                  component={CookCalculatorPage}
                  exact
                />
                {/* Cook dinners */}
                <UserRoute
                  path={"/user/dinners/create"}
                  component={CreateDinnerPage}
                  exact
                />
                <UserRoute
                  path={"/user/dinners/:id/update"}
                  component={EditDinnerPage}
                  exact
                />
                <UserRoute
                  path={"/dinners/:id/details"}
                  component={ViewDinnerDetailsPage}
                  exact
                />
                <UserRoute
                  path={"/dinners/:id/boost"}
                  component={BoostDinnerPage}
                  exact
                />
                <UserRoute
                  path={"/dinners/:id/request"}
                  component={DinnerRequestPage}
                  exact
                />
                <UserRoute
                  path={"/gourmet/setup/:stage"}
                  component={GourmetSetupPage}
                  exact
                />
                <UserRoute
                  path={"/user/orders/:id"}
                  component={OrderDetailsPage}
                  exact
                />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Suspense>
          </Sentry.ErrorBoundary>
        </ErrorBoundary>
      </GoogleAnalyticsWatcher>
    </BrowserRouter>
  );
};
