import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  becomeCookFormSchema,
  BecomeIBANForm,
} from "../pages/User/Settings/settings.validators";
import {
  BecomeCookError,
  GetUserRateResponse,
  userApiHooks,
} from "../api/user";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../store/user/actions";
import {
  ApiCustomError,
  getImageDetailsAsync,
  getImageSrc,
  handleApiCustomError,
  handleUnknownError,
} from "../utils/other";
import { Button, Form, Modal } from "react-bootstrap";
import { CustomIcon, FeatherIcon } from "../assets/icons";
import { DinnerRead, UploadedFile } from "../other/interfaces";
import ProgressBar from "react-customizable-progressbar";
import { Trans, useTranslation } from "react-i18next";
import {
  GoogleMap,
  GoogleMapProps,
  InfoBox,
  InfoBoxProps,
} from "@react-google-maps/api";
import {
  useDinnerProgress,
  useFormatDate,
  useJoiResolver,
  usePagination,
} from "../other/hooks";
import { StarRatings, UserProfilePhoto } from "./common";
import { useIntersection, useLatest } from "react-use";
import { Link } from "react-router-dom";
import { RootState } from "../store";
import { ImageUploaderProps, ImageUploaderWithCrop } from "./FileUpload";

export const BecomeCookFormModal = React.memo<{
  onClose: () => void;
  show: boolean;
  onSubmit?: (v: BecomeIBANForm) => void;
}>(({ onClose, show, onSubmit }) => {
  const [t] = useTranslation();
  const form = useForm<BecomeIBANForm>({
    resolver: useJoiResolver(becomeCookFormSchema),
  });
  const { errors } = form.formState;
  const request = userApiHooks.useBecomeCook();
  const dispatch = useDispatch();

  const handleSubmit = (validValues: BecomeIBANForm) => {
    if (onSubmit) return onSubmit(validValues);
    request
      .put(validValues)
      .then(() => {
        dispatch(userActions.becomeCook(validValues));
        onClose();
      })
      .catch((e) => {
        if (e instanceof ApiCustomError) {
          handleApiCustomError<BecomeCookError>(e, {
            "Invalid Iban": (message) => form.setError("iban", { message }),
          });
          return;
        }
        handleUnknownError(e);
      });
  };
  return (
    <Modal
      centered
      className="user-modal cook-report-modal"
      show={show}
      size={"md" as any}
    >
      <Modal.Body className="modal__body text-center">
        <Button
          className="btn-icon modal-close"
          variant="warning"
          onClick={onClose}
        >
          <CustomIcon.Close className="icon" />
        </Button>
        <h1 className="h4">{t("widgets.become_cook_modal.title")}</h1>

        <Form className="mt-4.5" onSubmit={form.handleSubmit(handleSubmit)}>
          <Form.Group controlId="iban" className="text-left">
            <Form.Label>{t("general.iban")}</Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              placeholder={t("general.iban_placeholder")}
              isInvalid={!!errors.iban}
              {...form.register("iban")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.iban?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="id_number" className="text-left">
            <Form.Label>{t("general.id_number")}</Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              placeholder={t("general.id_number_placeholder")}
              isInvalid={!!errors.id_number}
              {...form.register("id_number")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.id_number?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="secondary mt-3">
            {t("general.submit")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
export const DinnerProgressBar = React.memo<{
  dinner: DinnerRead;
  radius?: number;
}>(({ dinner, radius = 45 }) => {
  const [t] = useTranslation();
  const { minutesLeft, progress } = useDinnerProgress(dinner);

  return (
    <ProgressBar
      progress={progress}
      radius={radius}
      fillColor="#FFFFFF"
      strokeColor="#F4B840"
      pointerStrokeWidth={5}
      strokeWidth={5}
      trackStrokeWidth={5}
      trackStrokeColor="#EFEFEF"
      className="dinner__progress"
    >
      <div className="content">
        <div>
          <b>{minutesLeft}</b> <br />
          {t("general.minutes_short")}
        </div>
      </div>
    </ProgressBar>
  );
});

interface GoogleMapWidgetProps extends GoogleMapProps {
  pin?: {
    center: google.maps.LatLng | google.maps.LatLngLiteral;
    options?: InfoBoxProps;
    ref?: React.LegacyRef<InfoBox>;
  };
  mapRef?: React.LegacyRef<GoogleMap>;
}

export const GoogleMapWidget = React.memo<GoogleMapWidgetProps>(
  React.forwardRef<GoogleMap, GoogleMapWidgetProps>(
    ({ pin, mapRef, ...props }, ref) => {
      return (
        <GoogleMap {...props} ref={mapRef}>
          {pin && (
            <InfoBox
              position={pin.center}
              ref={pin.ref}
              options={{
                boxClass: "infoBox location-pin-infobox",
                pixelOffset: new google.maps.Size(-46 / 2, -46),
                ...pin.options,
              }}
            >
              <span className="location-pin" />
            </InfoBox>
          )}
          {props.children}
        </GoogleMap>
      );
    }
  )
);

export const CookRating: React.FC<{
  className?: string;
  rating: number | undefined | null;
}> = ({ className, rating = 4 }) => {
  return (
    <div className={className}>
      <StarRatings
        rating={rating ?? 0}
        starDimension={"17px"}
        name="cook-rating"
      />
    </div>
  );
};

export const UserReviewsSection = React.memo<{
  userId: number;
  className?: string;
  onTotalRateLoaded?: (r: number) => void;
}>(({ userId, className, onTotalRateLoaded }) => {
  const [t] = useTranslation();
  const pagination = usePagination();
  const paginationRef = useLatest(pagination);
  const formatDate = useFormatDate();
  const [reviews, setReviews] = useState<GetUserRateResponse["data"]>([]);

  const { response, isLoading } = userApiHooks.useUserRate(
    userId,
    pagination.currentPage
  );
  pagination.onPageCountChange(response?.last_page);

  // Infinite loader
  const intersectionRootRef = React.useRef(null);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: intersectionRootRef.current,
    rootMargin: "30px",
    threshold: 1,
  });

  useEffect(() => {
    const { pageCount, currentPage, onPageChange } = paginationRef.current;
    if (intersection?.isIntersecting && !isLoading) {
      const nextPage = currentPage + 1;
      if (nextPage > pageCount) return;
      onPageChange(nextPage);
    }
  }, [intersection, isLoading, paginationRef]);

  // Clear state on user change
  useEffect(() => {
    setReviews([]);
  }, [userId]);

  // Push current page data into array
  useEffect(() => {
    if (response) {
      onTotalRateLoaded?.(response?.total_rate);
      setReviews((arr) => [...arr, ...response.data]);
    }
  }, [onTotalRateLoaded, pagination.currentPage, response]);
  return (
    <div className={className}>
      {!!reviews.length && (
        <div>
          <b>{t("pages.view_user.reviews")}: </b>
          <div className="reviews" ref={intersectionRootRef}>
            {reviews.map((review) => {
              const { user } = review;
              return (
                <div key={review.id} className="review">
                  <div className="review__header">
                    <div className="review__author">
                      <UserProfilePhoto src={user.profile} />
                      <Link
                        to={`/users/${user.id}`}
                        className="review__author__name"
                      >
                        {user.name + " " + user.lastname}
                      </Link>
                    </div>
                    <span>{formatDate(review.created_at)}</span>
                  </div>
                  <div className="review__body">
                    <div className="d-flex align-items-center">
                      <span className="review__rating">
                        <FeatherIcon.Star className="icon" />
                        <span>{review.rate.toFixed(2)}</span>
                      </span>
                      <span className="review__dinner ml-2">
                        <Trans
                          i18nKey="widgets.user_reviews_section.dinner_link"
                          values={{ dinner_name: review.Dinner.dinner_name }}
                          components={{
                            dinnerLink: (
                              <Link
                                to={`/dinners/${review.Dinner.dinner_id}/details`}
                              />
                            ),
                          }}
                        />
                      </span>
                    </div>
                    <p className="review__comment">{review.comment}</p>
                  </div>
                </div>
              );
            })}
            <div ref={intersectionRef} className="infinite-scroll-trigger" />
          </div>
        </div>
      )}
    </div>
  );
});

export interface ProfileImageWithUploadProps {
  autoSubmit?: boolean;
  innerRef?: React.MutableRefObject<{
    submit: () => Promise<void>;
  }>;
}
export const ProfileImageWithUpload = React.memo<ProfileImageWithUploadProps>(
  ({ autoSubmit = true, innerRef }) => {
    const profileImage = useSelector((state: RootState) =>
      getImageSrc(state.user.user!.profile_image, "profile")
    );
    const [filePreview, setFilePreview] = useState<string>(profileImage);

    const uploadPhotoReq = userApiHooks.useUploadProfilePhoto();
    const onUpload = (file: UploadedFile) => {
      dispatch(userActions.changeProfilePhoto(file.file_name));
      setFilePreview(getImageSrc(file.file_name, "profile"));
      return file;
    };
    const dispatch = useDispatch();
    const uploader = async (data: FormData) => {
      return uploadPhotoReq
        .post(data)
        .then(onUpload)
        .catch((err) => {
          handleUnknownError(err);
          throw err;
        });
    };
    const handleCropPropsChange: ImageUploaderProps["onUploadPropsChange"] = async (
      config
    ) => {
      if (autoSubmit) {
        await innerRef?.current.submit();
        return;
      }
      // Crop and show cropped preview
      const imgDetails = await getImageDetailsAsync(config.file);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const [imgForZoom, imgForCrop] = [new Image(), new Image()];
      const scale = config.zoom;

      // Zoom first
      imgForZoom.onload = () => {
        canvas.width = imgDetails.width * scale;
        canvas.height = imgDetails.height * scale;

        ctx!.drawImage(
          imgForZoom,
          0,
          0,
          imgDetails.width,
          imgDetails.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
        imgForCrop.src = canvas.toDataURL(config.file.type, 1);
      };
      imgForZoom.src = imgDetails.url;

      // Then crop it
      imgForCrop.onload = () => {
        canvas.width = config.width;
        canvas.height = config.height;

        ctx!.drawImage(
          imgForCrop,
          config.x * scale,
          config.y * scale,
          config.width * scale,
          config.height * scale,
          0,
          0,
          config.width,
          config.height
        );
        const croppedImageURL = canvas.toDataURL(config.file.type, 1);
        setFilePreview(croppedImageURL);
        [canvas, imgForZoom, imgForCrop].forEach((el) => el.remove());
      };
    };

    return (
      <React.Fragment>
        <div className="user-img-upload">
          <div className="user-img">
            <img src={filePreview} alt="" className="avatar" />

            <ImageUploaderWithCrop
              uploader={uploader}
              resource="profile"
              autoSubmit={autoSubmit}
              innerRef={innerRef}
              onUploadPropsChange={handleCropPropsChange}
            >
              {({ labelFor }) => (
                <div className="icon-parent">
                  <label htmlFor={labelFor} className="m-0">
                    {uploadPhotoReq.loading ? (
                      <FeatherIcon.Loader
                        className="icon spinner-loader"
                        width={18}
                      />
                    ) : (
                      <FeatherIcon.LogOut className="icon rotate-45" />
                    )}
                  </label>
                </div>
              )}
            </ImageUploaderWithCrop>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export const TinymceContent = React.memo<{
  className?: string;
  children: string;
}>((props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const html = `
      <html>
          <head>
            <link rel="stylesheet" href="/assets/tinymce.css" />
          </head>
          <body style="background-color: transparent; overflow: visible">${props.children}</body>
        </html>
    `;

  const setWidth = () => {
    const body = iframeRef.current?.contentDocument?.body;
    if (!iframeRef.current || !body) return;
    const { scrollHeight } = body;
    console.debug("scrollHeight", scrollHeight);
    iframeRef.current!.style.height = scrollHeight + 50 + "px";
  };
  return (
    <iframe
      srcDoc={html}
      ref={iframeRef}
      onLoad={setWidth}
      width="100%"
      style={{ overflow: "hidden" }}
    />
  );
  // return (
  //   <p
  //     className={classNames("mce-content-body", props.className)}
  //     dangerouslySetInnerHTML={{ __html: props.children as string }}
  //   />
  // );
});
