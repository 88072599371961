import { io, Socket } from "socket.io-client";
import axios, { AxiosInstance } from "axios";

const SOCKET_URL = process.env.REACT_APP_WS_SOCKET!;
const SOCKET_PATH = "/ws";
let ws: Socket | undefined;
let axiosInstance: AxiosInstance | undefined;

export function getSocketConnection() {
  const token = localStorage.getItem("ACCESS_TOKEN") || "";
  return new Promise<Socket>((resolve, reject) => {
    if (ws) return resolve(ws);
    ws = io(SOCKET_URL, {
      query: { authorization: token },
      path: SOCKET_PATH,
    })
      .on("connect_error", reject)
      .on("connect", () => resolve(ws!));
  });
}

export function getHttpInstance() {
  const token = localStorage.getItem("ACCESS_TOKEN") || "";
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_WS_SERVER,
      responseType: "json",
    });
  }
  axiosInstance.defaults.headers.common.authorization = token;
  return axiosInstance;
}
