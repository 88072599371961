import {store} from "../store";
import {AccessToken} from "./storage";
import {UserRead} from "../other/interfaces";
import {userActions} from "../store/user/actions";

export interface LoginResponse {
    access_token: string;
    user: UserRead;
}

export const handleLoginResponse = async (res: LoginResponse) => {
    // const payload = jwt.decode(res.access_token) as AccessTokenPayload | null;
    // if (!payload) throw new Error("Invalid JWT Payload");

    // Save to localstorage
    AccessToken.set(res.access_token);
    // Login
    store.dispatch(userActions.login(res.user));
};

export const handleLogout = () => {
    // Save to localstorage
    AccessToken.unset();
    // Login
    store.dispatch(userActions.logout());
};
