import { ErrorReport, ValidationErrorFunction } from "joi";
import {
  ApiCustomErrorResponse,
  ApiFieldsErrorResponse,
  LangCode,
  Picture,
  PropByLang,
  SelectOption,
} from "../other/interfaces";
import { UseFormSetError } from "react-hook-form/dist/types/form";
import i18n from "../i18n";
import _ from "lodash";
import formatDateFns from "date-fns/format";
import isThisYear from "date-fns/isThisYear";
import isToday from "date-fns/isToday";
import { MEDIA_BASE_URLS } from "../config";
import { alertError, alertPopup } from "./alerts";
import { parseISO } from "date-fns";
import { Size } from "react-easy-crop/types";
import { Lang } from "./storage";
import * as Sentry from "@sentry/react";

export const overrideJoiCode = (
  mapObject: Record<string, string>
): ValidationErrorFunction => (errors: ErrorReport[]) => {
  errors.forEach((err) => {
    if (mapObject[err.code]) err.code = mapObject[err.code];
  });
  return errors as any;
};

export const htmlToString = (html: string): string => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  div.remove();
  return text.trim();
};

export class NotFoundError extends Error {
  constructor(message?: string) {
    super(message || "Resource Not Found");
    this.name = "NotFoundError";
  }
}

export class UnknownNetworkError extends Error {
  constructor(message?: string) {
    super(message || "Network Error");
    this.name = "UnknownNetworkError";
  }
}

export class ApiCustomError<T extends string = string> extends Error {
  data: ApiCustomErrorResponse<T>["error"] = null as any;

  constructor(payload: ApiCustomErrorResponse<T>) {
    super(payload.error);
    this.name = "ApiCustomError";
    this.data = payload.error;
  }
}

export class ApiFieldsError extends Error {
  data: ApiFieldsErrorResponse["errors"] = null as any;

  constructor(payload: ApiFieldsErrorResponse) {
    super(
      Object.values(payload.errors)
        .map((err) => err[0])
        .join("\n")
    );
    this.name = "ApiFieldsError";
    this.data = payload.errors;
  }
}

export const isApiCustomError = <T extends string>(
  err: unknown
): err is ApiCustomError<T> => err instanceof ApiCustomError;

export const getApiErrorMessage = (
  errorKey: string,
  i18Local = i18n
): string => {
  const ns = "api_errors";
  return i18Local.exists(errorKey, { ns })
    ? i18Local.t(errorKey, { ns })
    : errorKey;
};

export const handleApiFieldsError = (
  error: ApiFieldsError,
  setErrorFn: UseFormSetError<any>,
  i18Local = i18n
): void => {
  for (const key in error.data) {
    if (Object.prototype.hasOwnProperty.call(error.data, key)) {
      const errorKey = error.data[key][0];
      const message = getApiErrorMessage(errorKey, i18Local);
      setErrorFn(key, { message });
    }
  }
};

// eslint-disable-next-line no-redeclare
export function handleApiCustomError<T extends string = string>(
  error: ApiCustomError,
  callbacks?: Record<T, (message: string, err: T) => void>,
  i18Local?: typeof i18n
): void;
// eslint-disable-next-line no-redeclare
export function handleApiCustomError<T extends string = string>(
  error: ApiCustomError,
  callbacks?: (message: string, err: T) => void,
  i18Local?: typeof i18n
): void;
// eslint-disable-next-line no-redeclare
export function handleApiCustomError<T extends string = string>(
  error: ApiCustomError,
  callbacks?:
    | ((message: string, err: T) => void)
    | Record<T, (message: string, err: T) => void>,
  i18Local = i18n
): void {
  // Convert "handler" to object for single implementation
  if (typeof callbacks === "function") {
    const handlerFn = callbacks;
    callbacks = { [error.data]: handlerFn } as Record<
      string,
      (message: string, err: T) => void
    >;
  }
  if (typeof callbacks === "undefined") {
    const message = getApiErrorMessage(error.data, i18Local);
    alertError(undefined, message);
    return;
  }
  for (const errorId in callbacks) {
    if (Object.prototype.hasOwnProperty.call(callbacks, errorId)) {
      const handlerFn = callbacks[errorId];
      const message = getApiErrorMessage(errorId, i18Local);
      handlerFn(message, errorId);
    }
  }
}

export const handleUnknownError = (error: Error | unknown, i18Local = i18n) => {
  const title = i18n.t("general.error");
  let errorKey = `unknown_error`;

  if (Number.isInteger(parseInt((error as Error | undefined)?.name ?? "")))
    errorKey = `error_` + (error as Error).name;

  const message = getApiErrorMessage(errorKey, i18Local);
  alertPopup({ title, subtitle: message });
};

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  timeout: number
) {
  let timer: any;
  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

export function getSuspender(
  promise: Promise<any>,
  onError?: (e: any) => void
) {
  let status = "pending";
  let result: any;
  const suspender = promise
    .then(
      (r) => {
        status = "success";
        result = r;
      },
      (e) => {
        status = "error";
        result = e;
      }
    )
    .catch(onError);
  return {
    exec() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
}

export const getModifiedValues = (
  leftObject: Record<any, any>,
  rightObject: Record<any, any>
) => {
  const result: Record<any, any> = {};
  for (const key in leftObject) {
    if (Object.prototype.hasOwnProperty.call(leftObject, key)) {
      const leftValue = leftObject[key];
      if (!leftValue) continue;
      const rightValue = rightObject[key];
      const areEqual = _.isEqual(leftValue, rightValue);
      if (!areEqual) result[key] = leftObject[key];
    }
  }
  return result;
};

export function getImageSrc(
  images: string[] | undefined | null | Picture[],
  type: keyof typeof MEDIA_BASE_URLS
): string[];
// eslint-disable-next-line no-redeclare
export function getImageSrc(
  images: string | undefined | null | Picture,
  type: keyof typeof MEDIA_BASE_URLS
): string;
// eslint-disable-next-line no-redeclare
export function getImageSrc(
  images: string[] | string | undefined | null | Picture[] | Picture,
  type: keyof typeof MEDIA_BASE_URLS
): string | string[] {
  const getSrc = (arg: Picture | string | null | undefined): string => {
    let filename;
    if (arg) {
      if (typeof arg === "object") filename = arg!.name;
      else filename = arg;
    }

    if (!filename) {
      if (type === "profile") return "/assets/profile-photo-default.jpg";
      return "/assets/no-image.svg";
    }
    const baseURL = process.env.REACT_APP_PUBLIC_URL;
    if (!baseURL) throw new Error("PUBLIC_URL not set");
    const relativePath = MEDIA_BASE_URLS[type].image;
    return baseURL + relativePath + "/" + filename;
  };
  if (Array.isArray(images)) return images.map(getSrc);
  return getSrc(images);
}

export const getVideoSrc = (
  filename: string | undefined | null,
  type: keyof typeof MEDIA_BASE_URLS
): string => {
  if (!filename) return "";
  const baseURL = process.env.REACT_APP_PUBLIC_URL;
  if (!baseURL) throw new Error("PUBLIC_URL not set");
  const relativePath = MEDIA_BASE_URLS[type].video;
  return baseURL + relativePath + "/" + filename;
};

export const formatDate = (
  date: Date | string,
  // eslint-disable-next-line no-undef
  locale?: Locale,
  format?: string
): string => {
  try {
    const dt = typeof date === "string" ? parseISO(date) : new Date(date);
    if (format) return formatDateFns(dt, format, { locale });

    let day = formatDateFns(dt, "dd MMM yyyy", { locale });
    const hour = formatDateFns(dt, "HH:mm", { locale });
    if (isThisYear(dt)) day = formatDateFns(dt, "dd MMM", { locale });
    if (isToday(dt)) day = i18n.t("general.today");
    return `${day} | ${hour}`;
  } catch (e) {
    return "N/A";
  }
};

/**
 *
 * @param callback - function to call
 * @param ms - guaranteed delay
 */
export const getThrottledCallback = <U extends any[]>(
  callback: (...args: U) => void,
  ms: number
): ((...args: U) => void) => {
  const initializedAt = new Date().getTime();
  return (...args: U) => {
    const msDiff = new Date().getTime() - initializedAt;
    const callTimeout = Math.max(0, ms - msDiff);

    // eslint-disable-next-line node/no-callback-literal
    setTimeout(() => callback(...args), callTimeout);
  };
};

export const getImageDetailsAsync = (
  file: File
): Promise<{ width: number; height: number; url: string }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        const url = reader.result as string;
        const image = new Image();
        image.src = url;
        image.onload = () => {
          const { naturalWidth: width, naturalHeight: height } = image;
          resolve({ width, height, url });
        };
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsDataURL(file.slice());
  });

export function getCropSize(img: Size, min: Size, max: Size): Size {
  let width!: number, height!: number;
  const aspectRatio = max.width / max.height;

  // Check for minimal dimensions
  if (img.width < min.width || img.height < min.height) {
    throw new Error("Invalid image: " + JSON.stringify(img));
  }

  if (img.width < max.width || img.height < max.height) {
    const equalSides = img.width === img.height;

    // Case 1
    if (img.width > img.height || (equalSides && aspectRatio <= 1)) {
      height = img.height;
      width = (max.width / max.height) * height;
    }
    // Case 2
    else if (img.width < img.height || (equalSides && aspectRatio > 1)) {
      width = img.width;
      height = (max.height / max.width) * width;
    } else {
      throw new Error("Invalid case in calculations");
    }
  } else {
    width = max.width;
    height = max.height;
  }

  return { width, height };
}

export const getPublicURL = (path = ""): string => {
  const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
  return (PUBLIC_URL + path).replace(/(\w)\/\//g, "$1/");
};

export const getApiURL = (path = ""): string => {
  const PUBLIC_URL = process.env.REACT_APP_API_URL;
  return (PUBLIC_URL + path).replace(/(\w)\/\//g, "$1/");
};

// Used for typings
export const getPropByLang = <
  O extends Record<any, any>,
  P extends string,
  Keys = keyof PropByLang<P>,
  K = Keys extends keyof O ? Keys : never
>(
  obj: O,
  prop: P,
  lang: K extends never ? never : LangCode
): O[K] => {
  const key = `${prop}_${lang}` as keyof O;
  const fallbackKey = `${prop}_${Lang.fallback}` as keyof O;

  if (obj[key] === undefined) {
    const msg = `Key ${key} not found in object: ` + JSON.stringify(obj);
    console.warn(msg);
    Sentry.eventFromMessage({ enabled: true, debug: true }, msg);
  }

  return (obj[key] ?? obj[fallbackKey] ?? "") as any;
};
