import { Layout } from "../components/Layout";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "../assets/styles/pages/errors.scss";
import React, { ErrorInfo } from "react";
import { NotFoundError } from "../utils/other";
import { RouteComponentProps, useHistory } from "react-router";

export const NotFoundPage = () => {
  const [t] = useTranslation();
  return (
    <Layout className="error-page" pageId="not-found-page">
      <Container className="text-center">
        <span className="error__code"> 404 not found </span>
        <h2 className="error__title">{t("pages.404_error.title")}</h2>
        <p className="error__text">{t("pages.404_error.error_Text")}</p>
        <img src="/assets/pot.png" alt="404 error" className="error__img" />

        <a href="/" className="btn btn-primary">
          {t("general.back_to_homepage")}
        </a>
      </Container>
    </Layout>
  );
};

export const InternalErrorPage = () => {
  const [t] = useTranslation();
  const router = useHistory();
  return (
    <Layout className="error-page" pageId="internal-error-page">
      <Container className="text-center">
        <span className="error__code">{t("pages.internal_error.title")}</span>
        <p className="error__text">{t("pages.internal_error.error_Text")}</p>
        <img
          src="/assets/pot.png"
          alt="internal error"
          className="error__img"
        />

        {router ? (
          <a href="/" className="btn btn-primary mt-6">
            {t("general.back_to_homepage")}
          </a>
        ) : (
          <a href="/" className="btn btn-primary">
            {t("general.back_to_homepage")}
          </a>
        )}
      </Container>
    </Layout>
  );
};

class ErrorBoundaryClass extends React.Component<
  RouteComponentProps,
  { error: Error | undefined }
> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  // eslint-disable-next-line node/handle-callback-err
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    this.props.history.listen(() => this.setState({ error: undefined }));
  }

  render() {
    const { error } = this.state;
    if (!error) return this.props.children;

    if (error instanceof NotFoundError) return <NotFoundPage />;
    return <InternalErrorPage />;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryClass);
