import {
  ChatContact,
  ChatMessage,
  ChatPendingMessage,
  StartChatListener,
} from "./chat.interfaces";
import { createState, useState } from "@hookstate/core";
import { createGlobalState } from "react-use";
import React from "react";

export function debounceCallback<Fn extends (...args: any[]) => void>(
  func: Fn,
  timeout: number
) {
  let timer: any;
  return function (this: any, ...args: Parameters<Fn>): void {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) {
    return true;
  } else if (isObject(obj1) && isObject(obj2)) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (var prop in obj1) {
      if (!deepEqual(obj1[prop], obj2[prop])) {
        return false;
      }
    }
    return true;
  }

  // Private
  function isObject(obj: any) {
    if (typeof obj === "object" && obj != null) {
      return true;
    } else {
      return false;
    }
  }
}

export const isPendingMessage = (
  msg: ChatMessage | ChatPendingMessage
): msg is ChatPendingMessage =>
  (msg as ChatPendingMessage).sentAt !== undefined;

// Used to request chat from outside of the chat component
export const ChatBoxDispatcher = {
  _listeners: [] as Array<StartChatListener>,

  onStartChat(listener: StartChatListener) {
    if (!this._listeners.includes(listener)) this._listeners.push(listener);
  },

  startChat(userId: number) {
    this._listeners.forEach((listener) => listener(userId));
  },
};

interface ChatStateType {
  currentContact: ChatContact | null;
  initialized: boolean;
  initError: Error | null;
  userId: number;

  unseenCountByRoom: Record<number, number | undefined>;
  getUserImageUrl: (url: string | null) => string;
}
export const ChatState = createState<ChatStateType>({
  currentContact: null,
  initialized: false,
  initError: null,
  userId: 0,
  unseenCountByRoom: {} as Record<number, number | undefined>,
  getUserImageUrl: (value) => value || "",
});

export const useChatState = () => useState(ChatState);
