import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../../assets/styles/header.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { handleLogout } from "../../utils/auth";
import { getImageSrc } from "../../utils/other";
import { NotificationsDropdown } from "./Notifications";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { LangCode } from "../../other/interfaces";
import { Lang } from "../../utils/storage";

export const AppHeader: React.FC = () => {
  const isLoggedIn = useSelector<RootState>((state) => state.user.user);
  return (
    <header>
      <div className="container p-lg-0">
        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="light">
          <Navbar.Brand href="/" as={NavbarBrand}>
            <Logo className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <AppNav />
            <div className="navbar-left">
              {isLoggedIn ? (
                <AuthorizedNavLeftContent />
              ) : (
                <UnauthorizedNavLeftContent />
              )}
              <LanguageDropdown />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};
const NavbarBrand: React.FC<any> = (props) => (
  <Link {...props} to={props.href} />
);

const AuthorizedNavLeftContent = React.memo(() => {
  const name = useSelector((state: RootState) => state.user.user?.name);
  const profileImage = useSelector((state: RootState) =>
    getImageSrc(state.user.user!.profile_image, "profile")
  );
  const [t] = useTranslation();
  return (
    <>
      <NotificationsDropdown />
      <span className="greeting-text ml-3 mr-2">
        <Trans
          i18nKey="header.greeting_text"
          values={{ name }}
          components={{
            a: <Link to="/user/activity" className="text-bold text-black" />,
          }}
        />
      </span>
      <Dropdown className="menu-dropdown curve-dropdown">
        <Dropdown.Toggle
          id="menuDropdown"
          as={React.forwardRef<any, any>((p, ref) => (
            <a href="#" className="text-center" ref={ref} {...p}>
              <img src={profileImage} alt={name} className={`user-photo`} />
            </a>
          ))}
        >
          Dropdown Button
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="triangle" />
          <Dropdown.Item as={Link} to="/user/profile">
            {t("user_nav.profile")}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/change-password">
            {t("user_nav.change_password")}
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            {t("user_nav.logout")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
});

const UnauthorizedNavLeftContent = React.memo(() => {
  const [t] = useTranslation();
  return (
    <>
      <Link to="/login" className="btn btn-primary mr-2">
        {t("header.login_btn")}
      </Link>
      <Link to="/register" className="btn btn-light">
        {t("header.register_btn")}
      </Link>
    </>
  );
});
const AppNav = React.memo(() => {
  const [t] = useTranslation();
  const isLoggedIn = useSelector<RootState, boolean>(
    (state) => !!state.user.user
  );
  return (
    <>
      <Nav className="flex-grow-1 justify-content-center" as={"nav"}>
        {isLoggedIn && (
          <Nav.Link as={Link} to="/user/activity">
            {t("user_nav.activity")}
          </Nav.Link>
        )}
        <Nav.Link as={Link} to="/how-it-works">
          {t("header.menu.how_it_works")}
        </Nav.Link>
        <Nav.Link as={Link} to="/recipes">
          {t("header.menu.recipes")}
        </Nav.Link>
        <Nav.Link as={Link} to="/about">
          {t("header.menu.about_us")}
        </Nav.Link>
      </Nav>
    </>
  );
});

const languages: Record<LangCode, string> = {
  en: "Eng",
  ge: "ქარ",
  ru: "Рус",
};
const LanguageDropdown = React.memo(() => {
  const { i18n } = useTranslation();
  const selectedLng = i18n.language as LangCode;

  const languagesArray = Object.keys(languages) as LangCode[];
  return (
    <Dropdown className="language-dropdown curve-dropdown">
      <Dropdown.Toggle
        id="languageDropdown"
        as={React.forwardRef<any, any>((p, ref) => (
          <span ref={ref} {...p} className={`text-gray ${p.className}`}>
            {languages[selectedLng]}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          </span>
        ))}
      >
        Dropdown Button
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="triangle" />
        {languagesArray.map((lang) => (
          <Dropdown.Item
            key={lang}
            href="#"
            disabled={selectedLng === lang}
            onSelect={(e) => {
              // i18n.changeLanguage(lang)
              Lang.set(lang);
              window.location.reload();
            }}
          >
            {languages[lang]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});
